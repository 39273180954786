import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/resultaat/:id',
    name: 'Resultaat',
    props: castRouteParams,
    component: () =>
      import(/* webpackChunkName: "result" */ '../views/Result.vue'),
  },
  {
    path: '/v/:type',
    name: 'Introduction',
    props: castRouteParams,
    component: () =>
      import(
        /* webpackChunkName: "questionnaire" */ '../views/Introduction.vue'
      ),
  },
  {
    path: '/ps/:type',
    name: 'PreStart',
    props: castRouteParams,
    component: () =>
      import(
        /* webpackChunkName: "questionnaire" */ '../views/PreStart.vue'
      ),
  },
  {
    path: '/v/:type/:index',
    name: 'Questionnaire',
    props: castRouteParams,
    component: () =>
      import(
        /* webpackChunkName: "questionnaire" */ '../views/Questionnaire.vue'
      ),
  },
  {
    path: '/v/:type/resultaat',
    name: 'QuestionnaireResult',
    props: castRouteParams,
    component: () =>
      import(/* webpackChunkName: "result" */ '../views/Result.vue'),
  },
  {
    path: '/d/:type/:organisationId',
    name: 'Hallway',
    props: castRouteParams,
    component: () =>
      import(/* webpackChunkName: "questionnaire" */ '../views/Hallway.vue'),
  },
  {
    // When no other route is matched we show a 404. Needs to be at the very end.
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
]

function castRouteParams(route) {
  return {
    id: route.params.id,
    index: Number(route.params.index),
    type: route.params.type,
  }
}

// Used to check if query params are set
// @link https://stackoverflow.com/questions/45091380/vue-router-keep-query-parameter-and-use-same-view-for-children
function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // Scroll to top at every navigation
    document.getElementById('app').scrollIntoView()
  },
})

router.beforeEach((to, from, next) => {
  if (to.params.type) {
    store.commit('SET_TYPE', to.params.type)
  }

  // Maintain query params throughout nav
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ ...to, query: from.query })
  } else {
    next()
  }
})

export default router
