import router from '@/router'
import {createResult} from '@/services/resultService.js'
import { useSessionStorage } from '@vueuse/core'

export const answersModule = {
  namespaced: true,
  state: () => ({
    answers: useSessionStorage('answers', {}),
    openAnswers: []
  }),
  mutations: {
    ADD(state, payload) {
      if (!state.answers[payload.type]) {
        state.answers[payload.type] = {}
      }
      state.answers[payload.type][payload.index] = payload.answers
    },
    ADD_OPEN(state, payload) {
      state.openAnswers.push(payload.answer)
    },    
    RESET(state) {
      state.answers = {}
    },
  },
  actions: {
    submitAnswer({ commit, rootState }, payload) {
      commit('ADD', { ...payload, type: rootState.status.type })
    },
    submitOpenAnswer({ commit, rootState}, payload) {
      commit('ADD_OPEN', { ...payload, type: rootState.status.type })
    },
    async submitResults({ getters, rootState, state }) {
      // eslint-disable-line
      let simplified = getters.structuredResults

      if ( simplified.length == 0) {
        return // Bail when we have no results
      }

      // sort simplified by percentage and map to results
      const mailResults = [...simplified].reduce((carry, val, index) => {
        carry['result'+index] = {mask:val.mask, percentage:Math.round(val.percent)}
        return carry
      },{})

      let createData = {
        simplified: simplified,
        type: rootState.status.type,
        language: rootState.status.language,
        organisationId: rootState.status.organisationId,
        full: state.answers[rootState.status.type],
        openAnswers: state.openAnswers
      }

      const openAnswers = [...state.openAnswers].reduce((carry, val, index) => {
        carry['answer'+index] = val
        return carry
      },{})

      // Only add mail info when template is specified
      if ( rootState.status.mailTemplate ) {
        createData = {...createData, 
          to: rootState.status.isForEmail,
          template: { 
            data: {...mailResults, fromName: rootState.status.fromName, forName: rootState.status.isFor, openAnswers: openAnswers },
            name: rootState.status.mailTemplate
          }
        }
      }

      return await createResult(createData)
        .then((response) => {
          if (response) {
            // return link
            const resolved = router.resolve({
              name: 'Resultaat',
              params: { id: response.data.resultId },
            })
            return window.location.origin + resolved.href
          }
        })
    },
  },
  getters: {
    mergedResults(state, getters, rootState) {
      const type = rootState.status.type
      return state.answers[type] ? mergeResults(state.answers[type]) : null
    },
    structuredResults(state, getters, rootState) {
      // eslint-disable-line
      let result = []

      const mergedResults = getters.mergedResults
      // console.log(
      //   '🚀 ~ file: answers.module.js ~ line 46 ~ structuredResults ~ mergedResults',
      //   mergedResults
      // )

      if (!mergedResults) {
        return []
      }

      const totalPoints = Object.values(mergedResults).reduce((a, b) => a + b)
      let arr = Object.values(mergedResults)
      const maxPoints = Math.max(...arr)

      // Floor percent (value / totalPoints) * 100
      const flooredPercentages = Object.entries(mergedResults).reduce((acc,[key, value]) => {
        const floored = Math.floor((value / totalPoints) * 100)
        acc.push({
          key: key,
          floored: floored,
          decimals: (value / totalPoints) * 100 - floored // Keep decimals ( percent - floored percentage) IE: 0.43
        })
        return acc
      }, []).sort(function(a, b) {
        return a.decimals < b.decimals; // sort by decimals
      })

      // Calculate leftovers
      const leftOvers = 100 - Object.values(flooredPercentages).reduce((a, b) => a + b.floored, 0)
      
      // Add leftovers to floored percentages that deserve it.
      flooredPercentages.map((item, index) => {
        if (index < leftOvers) {
          item.floored += 1
        }
      })

      for (const [key, value] of Object.entries(mergedResults)) {
        // eslint-disable-line
        if (rootState.masks[rootState.status.type] && rootState.masks[rootState.status.type][key]) {
          result.push({
            key: key,
            mask: rootState.masks[rootState.status.type][key].name,
            value: value,
            percent: flooredPercentages.find(item => item.key === key).floored,
            percentRelative: (value / maxPoints) * 100,
          })
        }

        // console.log(value)
      }
      return result
      // return result.sort(function(a, b) {
      //   return a.value < b.value;
      // });
    },
  },
}

// Merge the answers by mask
// Based on @link https://dev.to/ramonak/javascript-how-to-merge-multiple-objects-with-sum-of-values-43fd
const mergeResults = (data) => {
  // eslint-disable-line
  const result = {} //(1)

  for (const [pkey, pvalue] of Object.entries(data)) {
    // eslint-disable-line
    pkey
    for (let [key, value] of Object.entries(pvalue)) {
      //(3)
      if (result[key]) {
        //(4)
        result[key] += value //(5)
      } else {
        //(6)
        result[key] = value
      }
    }
  }
  return result //(7)
}
