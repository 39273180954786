export const stringModule = {
  namespaced: true,
  state: () => ({
    backResult: {
      nl: 'Terug naar resultaat',
      en: 'Back to result',
    },
    maskInfo: {
      nl: 'Uitleg',
      en: 'Explanation',
    },
    toAnswers: {
      nl: 'Antwoorden',
      en: 'Answers',
    },
    answer: {
      nl: 'Antwoorden',
      en: 'Answers',
    },
    nextQuestion: {
      nl: 'Volgende vraag',
      en: 'Next question',
    },
    toTest: {
      nl: 'Start de scan',
      en: 'To the scan',
    },
    watFijn: {
      nl: 'Hi!<br>Wat fijn dat je de tijd wilt nemen om deze scan in te vullen.',
      en: 'Hi!<br>Great that you\'re willing to take the time to complete this scan.'
    },
    yourNameLabel: {
      nl: 'Wat is je naam?',
      en: 'What is your name?'
    },
    namePlaceholder: {
      nl: 'Naam',
      en: 'Name'
    },
    forWhoLabel: {
      nl: 'Voor wie vul je de scan in?',
      en: 'For whom are you filling out this scan?'
    },
     otherEmailLabel: {
      nl: 'Wat is het e-mail adres van de persoon voor wie je de scan invult?',
      en: 'What is the email address of the person for whom you are filling out the scan?'
    },
    emailPlaceholder: {
      nl: 'E-mail',
      en: 'Email'
    },
    letsGo: {
      nl: 'Let\'s go',
      en: 'Let\'s go',
    },
    yourEmail: {
      nl:'Vul hier je e-mailadres in. Die gebruiken we om je de resultaten te sturen.',
      en: 'Enter your email address here. We use it to send you the results.'
    },
    resultHeading: {
      nl: 'Dit is het resultaat:',
      en: 'This is the result:',
    },
    createLink: {
      nl: 'Bewaar mijn resultaten',
      en: 'Save my results',
    },
    urlToResult: {
      nl: 'Link naar resultaat',
      en: 'Link to result',
    },
    createLinkExplanation: {
      nl: 'Om jouw resultaat te bewaren of te delen kunnen we een unieke link voor je aanmaken. Let op, zonder deze link gaan je resultaten verloren.',
      en: 'In order to save or share your results we can create a unique link for you. Be aware, without this link your results will not be saved.',
    },
    // savingResult: {
    //   nl: 'Opslaan...',
    //   en: 'Saving...',
    // },
    shareMail: {
      nl: 'Deel link via e-mail',
      en: 'Share link by email.',
    },
    mailtoLink: {
      nl: 'mailto:?subject=Ik heb de u-scan van UGURU voor je ingevuld&body=Bekijk de resultaten van de u-scan die ik voor jou heb ingevuld via deze link: ',
      en: 'mailto:?subject=UGURU Scan result&body=View the results of the UGURU scan I filled in for you: ',
    },
    copyUrl: {
      nl: 'Kopieer link',
      en: 'Copy link'
    },
    receiveResults: {
      nl: 'Je ontvangt de resultaten via e-mail.',
      en: 'You will receive the results via email.'
    },
    dividePoints: {
      nl: 'Verdeel hieronder 10 punten.',
      en: 'Divide 10 points below.'
    },
    send: {
      nl: 'Verzenden',
      en: 'Send'
    }
  }),
  getters: {
    getString(state, getters, rootState) {
      return (string) => state[string][rootState.status.language]
    },
  },
}
