import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// import firebase from 'firebase/app';
// import 'firebase/auth';
// import './services/firebaseInit';
// import { firebaseApp } from './services/firebaseInit'

createApp(App).use(store).use(router).mount('#app')
