<template>
  <div>
    <h1>Page not found</h1>
    <p>The content you are looking for is gone or has been moved.</p>
  </div>
</template>

<style lang="scss" scoped>
h1,
p {
  text-align: center;
}
</style>