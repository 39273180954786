import { createStore } from 'vuex'
import { answersModule } from './modules/answers.module'
import { stringModule } from './modules/string.module'
import { useSessionStorage } from '@vueuse/core'

export default createStore({
  state: {
    status: {
      type: null,
      question: null,
      language: 'nl',
      isFor: useSessionStorage('isFor',''),
      isForEmail: useSessionStorage('isForEmail',''),
      fromName: useSessionStorage('fromName',''),
      mailTemplate: null,
      organisationId: '',
    },
    // For some reason useStorage is not working
    // isFor: useStorage('is-for', { name: '' }),

    questionnaires: [
      {
        name: 'persoonlijk',
        language: 'nl',
        introductionStart: require('@/assets/html/intro-persoonlijk-start.html'),
        introductionEnd: require('@/assets/html/intro-persoonlijk-end.html'),
        questions: [
          {
            question: 'Hoe was jij als kind?',
            header: '',
            options: [
              { mask: 'a', label: 'Dromend in mijn eigen fantasiewereld' },
              // {
              //   mask: 'b',
              //   label: 'Als ik iemand blij heb gemaakt met mijn hulp',
              // },
              {
                mask: 'c',
                label: 'Allemans vriend ',
              },
              {
                mask: 'd',
                label: 'De leider op het schoolplein',
              },
              {
                mask: 'e',
                label: 'Iets te verantwoordelijk voor mijn leeftijd ',
              },
              {
                mask: 'f',
                label: 'De beste van de klas',
              },
            ],
          },
          {
            question: 'Wat gaat jou niet zo makkelijk af?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Over koetjes en kalfjes kletsen op een drukke borrel',
              },
              // { mask: 'b', label: 'Dat ik altijd voor iedereen klaar sta' },
              { mask: 'c', label: 'Moeilijke knopen doorhakken' },
              {
                mask: 'd',
                label: 'Anderen voor de volle 100% vertrouwen',
              },
              { mask: 'e', label: 'Mijn eigen successen vieren' },
              {
                mask: 'f',
                label: 'Mijn hoge standaarden loslaten',
              },
            ],
          },
          {
            question: 'Wat is jouw grootste kwaliteit?',
            header: '',
            options: [
              { mask: 'a', label: 'Het grotere plaatje zien' },
              // { mask: 'b', label: 'Als geven en nemen in balans is' },
              { mask: 'c', label: 'Kunnen opschieten met iedereen' },
              { mask: 'd', label: 'De status-quo uitdagen.' },
              { mask: 'e', label: 'Betrouwbaar zijn' },
              { mask: 'f', label: 'Dingen realiseren ' },
            ],
          },
          {
            question: 'Waar ben je het allerbangst voor?',
            header: '',
            options: [
              { mask: 'a', label: 'Om niet begrepen te worden' },
              // {
              //   mask: 'b',
              //   label: 'Ik hoop dat ik kan bijdragen aan meer rechtvaardigheid',
              // },
              {
                mask: 'c',
                label: 'Om anderen te kwetsen',
              },
              {
                mask: 'd',
                label: 'Om kwetsbaar te zijn',
              },
              { mask: 'e', label: 'Om onbelangrijk te zijn' },
              {
                mask: 'f',
                label: 'Om fouten te maken',
              },
            ],
          },
          {
            question: 'Waar ben je beter in dan anderen?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik kan out-of-the-box denken',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik ben warm, attent en rechtvaardig, ik weet precies wat iedereen nodig heeft',
              // },
              {
                mask: 'c',
                label: 'Ik kan mensen op hun gemak stellen',
              },
              {
                mask: 'd',
                label:
                  'Ik zet mijn kracht in om dingen voor elkaar te krijgen. ',
              },
              {
                mask: 'e',
                label: 'Ik kan de verantwoordelijkheid dragen',
              },
              {
                mask: 'f',
                label: 'Ik weet precies hoe het er uit moet komen te zien ',
              },
            ],
          },
          {
            question: 'Hoe zien andere mensen jou?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'Ze vinden me inspirerend en onafhankelijk, maar ook afstandelijk',
              },
              // { mask: 'b', label: 'Ik ben super attent en vrijgevig' },
              {
                mask: 'c',
                label:
                  'Ze voelen zich erkend en begrepen, maar ze weten niet precies wat er in mij omgaat',
              },
              {
                mask: 'd',
                label: 'Ze vinden me sterk en dapper, maar ook een beetje hard',
              },
              {
                mask: 'e',
                label:
                  'Ze vertrouwen me en kunnen op me bouwen, maar er is niet veel ruimte voor plezier',
              },
              {
                mask: 'f',
                label:
                  'Ze hebben het gevoel dat het in goede handen is, maar vinden me ook veeleisend',
              },
            ],
          },
          {
            question: 'Hoe reageer je onder druk?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik trek me terug en kijk de kat uit de boom',
              },
              // {
              //   mask: 'b',
              //   label: 'Vriendelijk en vrijgevig, maar toch ook veeleisend',
              // },
              {
                mask: 'c',
                label:
                  'Ik pas me aan en probeer het iedereen naar de zin te maken',
              },
              {
                mask: 'd',
                label:
                  'Ik verzin een strijdplan en push anderen tot het uiterste',
              },
              {
                mask: 'e',
                label:
                  'Ik werk 24/7 om ervoor te zorgen dat het geregeld wordt',
              },
              {
                mask: 'f',
                label:
                  "Ik blijf bij m'n standaarden, trek de teugels aan en wordt ook directief",
              },
            ],
          },
          {
            question: 'Wat bewonderen anderen in jou?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Dat ik creatief ben',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Dat ik met complimenten strooi en nooit iemands verjaardag vergeet',
              // },
              {
                mask: 'c',
                label: 'Dat ik goed kan samenwerken',
              },
              {
                mask: 'd',
                label: 'Dat ik zelfstandig ben',
              },
              {
                mask: 'e',
                label: 'Dat ik lang en hard kan werken en nooit opgeef',
              },
              {
                mask: 'f',
                label: 'Dat ik nooit de controle verlies',
              },
            ],
          },
          {
            question: 'Zo nu en dan zeggen mensen dat...',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'ik te afstandelijk ben',
              },
              // {
              //   mask: 'b',
              //   label:
              //     "Ik kan soms een beetje 'needy' overkomen, het is eigenlijk nooit genoeg",
              // },
              {
                mask: 'c',
                label: 'ik te zachtaardig ben',
              },
              {
                mask: 'd',
                label: 'ik te bot ben',
              },
              {
                mask: 'e',
                label: 'ik te serieus ben',
              },
              {
                mask: 'f',
                label: 'ik te kritisch ben',
              },
            ],
          },
          {
            question: 'Wat zegt jouw innerlijke criticus?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Niemand begrijpt me écht',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Duizend verwijten in onweerlegbare zinnen. Geen speld tussen te krijgen',
              // },
              {
                mask: 'c',
                label: 'Mijn mening doet er niet toe',
              },
              {
                mask: 'd',
                label: 'Niemand mag mijn zwaktes zien',
              },
              {
                mask: 'e',
                label: 'Ik moet doorgaan, want dat is de enige optie',
              },
              {
                mask: 'f',
                label: 'Ik ben zo goed als mijn laatste succes ',
              },
            ],
          },
          {
            question: 'Waarin kan je soms doorslaan?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik verlies de praktische kant uit het oog',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik voel me vaak onmachtig en gefrustreerd, het leven voelt vaak oneerlijk',
              // },
              {
                mask: 'c',
                label: 'Ik ben een pleaser',
              },
              {
                mask: 'd',
                label: 'Ik ben intimiderend',
              },
              {
                mask: 'e',
                label: "Ik neem meer last op m'n schouders dan goed voor me is",
              },
              {
                mask: 'f',
                label: 'Ik kan een controlfreak zijn',
              },
            ],
          },
          {
            question: 'Hoe rechtvaardig je je eigen gedrag?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'Ik weet dat ik veel woorden gebruik, maar ik moet het gewoon beter uitleggen',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik weet dat ik een kameleon ben, maar ik wil graag de harmonie bewaren',
              // },
              {
                mask: 'c',
                label:
                  'Ik weet dat ik een kameleon ben, maar ik wil graag de harmonie bewaren',
              },
              {
                mask: 'd',
                label:
                  'Ik weet dat ik direct ben, maar het doel heiligt de middelen',
              },
              {
                mask: 'e',
                label:
                  'Ik weet dat ik slecht grenzen kan stellen, maar als ik het niet doe, doet niemand het',
              },
              {
                mask: 'f',
                label:
                  'Ik weet dat ik opdringerig ben, maar ik wil gewoon dat het goed gaat',
              },
            ],
          },
          {
            question: 'Hoe reageer je op een conflict of discussie?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'Een discussie met mij lijkt meer op een intellectueel debat',
              },
              // { mask: 'b', label: 'Dat er niet genoeg is voor mij ' },
              {
                mask: 'c',
                label: 'Ik heb nooit conflicten, ik bewaar liever de harmonie',
              },
              {
                mask: 'd',
                label:
                  'Als mensen me te ver pushen, dan trek ik de touwtjes strakker aan',
              },
              {
                mask: 'e',
                label:
                  'Ik bijt mijn frustratie weg en ploeter stilletjes voort',
              },
              {
                mask: 'f',
                label:
                  'Ik houd mijn hoofd koel en laat de discussie niet uit de hand lopen.',
              },
            ],
          },
          {
            question:
              'Wanneer heb je het idee dat je helemaal op je plek bent?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'In creatieve situaties waar mijn ideeën en idealen gewaardeerd worden.',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label: 'In informele situaties, waar iedereen elkaar kent ',
              },
              {
                mask: 'd',
                label:
                  "In competitieve situaties, waar het 'wij tegen zij' is.",
              },
              {
                mask: 'e',
                label:
                  'In moeilijke situaties, waar mensen echt op mij moeten kunnen rekenen.',
              },
              {
                mask: 'f',
                label:
                  'In veeleisende situaties, waar er veel energie en gedrevenheid is',
              },
            ],
          },
        ],
      },
      {
        name: 'organisatie',
        language: 'nl',
        introductionStart: require('@/assets/html/intro-organisatie-start.html'),
        introductionEnd: require('@/assets/html/intro-organisatie-end.html'),
        questions: [
          {
            question: 'Wanneer is deze organisatie op haar allerbest?',
            header: 'Beste moment',
            options: [
              {
                mask: 'a',
                label: 'In de brainstormfase waar alles nog mogelijk is ',
              },
              {
                mask: 'b',
                label:
                  'In situaties waarin iedereen ons nodig heeft en we er toe doen',
              },
              { mask: 'c', label: 'Tijdens sociale evenementen' },
              { mask: 'd', label: 'Als er snelheid en daadkracht nodig is ' },
              {
                mask: 'e',
                label:
                  'Als het draait om hogere waarden zoals zingeving, onbaatzuchtigheid en integriteit',
              },
              {
                mask: 'f',
                label:
                  'Als het helemaal moet kloppen, als details ertoe doen en er niks fout mag gaan',
              },
            ],
          },
          {
            question:
              'Wat is in essentie de grootste kracht van deze organisatie?',
            header: 'Kracht',
            options: [
              {
                mask: 'a',
                label: 'Een autonome visie op complexe vraagstukken',
              },
              {
                mask: 'b',
                label:
                  'Anderen een goed gevoel kunnen geven, blij kunnen maken of kunnen helpen',
              },
              {
                mask: 'c',
                label: 'Het gezelligste en leukste bedrijf om voor te werken',
              },
              { mask: 'd', label: 'De allerbeste zijn' },
              { mask: 'e', label: 'Wereldverbeteraars' },
              {
                mask: 'f',
                label: 'Indrukwekkende prestaties neerzetten in de wereld',
              },
            ],
          },
          {
            question: 'Waar worden mensen in deze organisatie echt blij van?',
            header: 'Energie, blijdschap',
            options: [
              {
                mask: 'a',
                label: 'Confused complexity, hoe ingewikkelder hoe leuker',
              },
              {
                mask: 'b',
                label:
                  'Dat we gezien worden in het betekenisvolle werk dat we doen ',
              },
              { mask: 'c', label: 'Samen iets voor elkaar krijgen' },
              { mask: 'd', label: 'De concurrent verslaan' },
              {
                mask: 'e',
                label:
                  'Met hart en ziel inzetten voor wat er maatschappelijk toe doet ',
              },
              {
                mask: 'f',
                label: "De kick van een 'flawless and amazing execution'",
              },
            ],
          },
          {
            question:
              'Vanuit welke drijfveren werken mensen bij deze organisatie?',
            header: 'Drijfveren',
            options: [
              {
                mask: 'a',
                label:
                  'Vernieuwende ideeën en innovaties die leiden tot een betere toekomst',
              },
              {
                mask: 'b',
                label:
                  'Zorgen voor een rechtvaardige wereld waarin iedereen een plek heeft',
              },
              {
                mask: 'c',
                label:
                  'Helemaal jezelf mogen zijn, het geeft niks als je onzeker bent',
              },
              {
                mask: 'd',
                label: 'Veel invloed hebben en impact kunnen maken ',
              },
              { mask: 'e', label: 'Betekenisvol werk kunnen doen' },
              {
                mask: 'f',
                label:
                  'Willen voldoen aan de hoge lat, en dan nog net ietsje beter. ',
              },
            ],
          },
          {
            question:
              'Wat voor soort mensen worden leider in deze organisatie?',
            header: 'Leiderschap',
            options: [
              {
                mask: 'a',
                label:
                  "De mensen die de meeste kennis hebben ('managers' zonder inhoud worden niet geaccepteerd)",
              },
              {
                mask: 'b',
                label: 'Mensen die het fijn vinden om onmisbaar te zijn',
              },
              {
                mask: 'c',
                label:
                  'Een leider mag niet te dominant zijn maar ondertussen wordt er veel van je verwacht',
              },
              {
                mask: 'd',
                label:
                  'Er is bewondering voor leiders maar ook wantrouwen en angst',
              },
              {
                mask: 'e',
                label:
                  'Leiderschap is een kwestie van verantwoordelijkheid dragen en offers brengen',
              },
              {
                mask: 'f',
                label:
                  'Organisatietalenten die niets aan het toeval over laten.',
              },
            ],
          },
          {
            question: 'Hoe is de sfeer op kantoor?',
            header: 'Gezelschap, sociaal verkeer',
            options: [
              {
                mask: 'a',
                label:
                  'Alle gesprekken gaan over de inhoud, het zijn een stel vakidioten onder elkaar',
              },
              {
                mask: 'b',
                label:
                  'We hebben het altijd druk, maar er komt niet altijd iets concreets uit onze handen',
              },
              {
                mask: 'c',
                label:
                  'Bij ons is het altijd een gezellige chaos, veel gezamenlijke momenten',
              },
              {
                mask: 'd',
                label:
                  'We zijn optimistisch en oplossingsgericht, we houden van uitdaging en van avontuur',
              },
              {
                mask: 'e',
                label:
                  "We zijn serieus en gedreven, er heerst een gevoel van 'moeten', weinig lichtheid en plezier",
              },
              {
                mask: 'f',
                label:
                  'Overal is over nagedacht en alles klopt, er wordt keihard gewerkt',
              },
            ],
          },
          {
            question: 'Hoe ervaren klanten deze organisatie?',
            header: 'Impact op de buitenwereld',
            options: [
              {
                mask: 'a',
                label:
                  'Klanten kijken een beetje tegen ons op, hier werken de slimmerds',
              },
              {
                mask: 'b',
                label:
                  "We zeggen 'ja' op alle vragen (en raken dus altijd ondergesneeuwd)",
              },
              {
                mask: 'c',
                label:
                  'Deze club heeft een grote gunfactor, ze voelen bijna als vrienden',
              },
              {
                mask: 'd',
                label:
                  'Als je een pitbull nodig hebt, moet je deze organisatie hebben',
              },
              {
                mask: 'e',
                label:
                  'Je kunt altijd op deze organisatie vertrouwen, ze laten je nooit in de steek',
              },
              {
                mask: 'f',
                label:
                  'Zij hebben hun zaakjes altijd piekfijn voor elkaar, je hoeft je nooit zorgen te maken',
              },
            ],
          },
          {
            question: 'Waarom is het leuk om bij dit bedrijf te werken?',
            header: 'Positief effect op anderen',
            options: [
              {
                mask: 'a',
                label:
                  'Creativiteit en ideeën komen echt tot hun recht, daar draait het om',
              },
              {
                mask: 'b',
                label:
                  'Er heerst een zorgzame sfeer, waar er altijd oog is voor mensen',
              },
              {
                mask: 'c',
                label:
                  'Je kunt jezelf echt laten zien, het voelt bijna als een familie',
              },
              {
                mask: 'd',
                label:
                  'Op je bek gaan is prima, als je er maar van leert. Het is work hard, play hard',
              },
              {
                mask: 'e',
                label: 'Hier kan je straks bij de hemelpoort wel mee aankomen',
              },
              {
                mask: 'f',
                label:
                  'Het is heel prestigieus om bij dit bedrijf te mogen werken',
              },
            ],
          },
          {
            question: 'Waar gaat het gedoe in deze organisatie vaak over?',
            header: 'Negatief effect op anderen',
            options: [
              {
                mask: 'a',
                label:
                  'Wat we uitwisselen zijn meningen, wat we voor ons houden zijn onderlinge frustraties',
              },
              {
                mask: 'b',
                label:
                  'We zijn zo bezig met wat de wereld van ons vraagt dat wij elkaar vergeten',
              },
              {
                mask: 'c',
                label:
                  'Als er geen harmonie is ontstaat er paniek en ruzie, daar kunnen we niet mee omgaan',
              },
              {
                mask: 'd',
                label:
                  'De gevoelige typjes hebben het zwaar hier, het is niet voor de faint-hearted',
              },
              {
                mask: 'e',
                label:
                  'We ploeteren maar door, maar we komen nooit aan. Gestaag, maar ook traag',
              },
              {
                mask: 'f',
                label:
                  'Geroddel over mensen die niet voldoen aan de (hoge) norm',
              },
            ],
          },
          {
            question: 'Wat gebeurt er als er een conflict is?',
            header: 'Conflicthantering',
            options: [
              {
                mask: 'a',
                label:
                  'Conflicten zijn een soort mentale schaakspellen of worden vermeden',
              },
              {
                mask: 'b',
                label:
                  'We maken het groter dan het al is, het voelt als onrecht, een emotioneel moeras',
              },
              {
                mask: 'c',
                label: 'Dreigende conflicten worden omzeild of gesust',
              },
              {
                mask: 'd',
                label:
                  'Conflict hoort er gewoon bij. We strijden met open vizier',
              },
              {
                mask: 'e',
                label:
                  'We vinden er van alles van maar slikken dat in en gaan gewoon weer door',
              },
              {
                mask: 'f',
                label:
                  'Een eindeloze woorden- of e-mail stroom waarin het gelijk onweerlegbaar wordt aangetoond',
              },
            ],
          },
          {
            question: 'Hoe wordt er omgegaan met macht en hiërarchie?',
            header: 'Macht',
            options: [
              {
                mask: 'a',
                label:
                  'Kennis is macht, hiërarchie doet er niet toe, dat zien we meer als corvee ',
              },
              {
                mask: 'b',
                label:
                  'Groot beroep op sturing en coaching op het MT, evenals verwijt dat ze het niet goed doen',
              },
              {
                mask: 'c',
                label:
                  'Power is in the people, we gaan er samen voor. Macht is geen aantrekkelijk woord',
              },
              {
                mask: 'd',
                label:
                  "Macht wordt bewierookt, it's up or out. Er is hier geen plek voor verliezers",
              },
              {
                mask: 'e',
                label: 'Normen en waarden worden door de leiding bewaakt',
              },
              {
                mask: 'f',
                label:
                  'Onze organisatie accepteert dienend leiderschap met een sterk moreel anker',
              },
            ],
          },
          {
            question:
              'Wat zijn de diepe overtuigingen (scripts) die leven in deze organisatie?',
            header: 'Scripts',
            options: [
              {
                mask: 'a',
                label:
                  'Alleen als we briljant zijn hebben we een plek in de wereld verdiend',
              },
              {
                mask: 'b',
                label:
                  'Als we nog maar een extra stap zetten krijgen we misschien een schouderklopje',
              },
              {
                mask: 'c',
                label:
                  'Samen staan we sterk en meedoen is belangrijker dan winnen ',
              },
              {
                mask: 'd',
                label:
                  'We hebben geen zwakke plekken en tenzij er een enorme crisis is, hoeven we niks te leren',
              },
              {
                mask: 'e',
                label:
                  'We moeten doorzetten en volhouden, dat is wat er nodig is en uiteindelijk helpt',
              },
              {
                mask: 'f',
                label:
                  'We laten niets aan het toeval over, dan gaat het geheid mis',
              },
            ],
          },
          {
            question:
              'Wat wordt in deze organisatie als de grootste bedreiging gezien?',
            header: 'Diepste angst',
            options: [
              { mask: 'a', label: 'Rem op creativiteit en innovatie' },
              { mask: 'b', label: 'Dat we er niet toe doen' },
              {
                mask: 'c',
                label:
                  'Als de harmonie wordt doorbroken zijn we bang dat het uit elkaar valt',
              },
              { mask: 'd', label: 'Verslagen worden door de concurrent' },
              { mask: 'e', label: 'Dat al ons gesjouw voor niks is geweest' },
              {
                mask: 'f',
                label:
                  'Dat we een fout hebben gemaakt en de klant niet tevreden is',
              },
            ],
          },
          {
            question: 'Wat is de oorspronkelijke purpose van deze organisatie?',
            header: 'Oorsponkelijke drijfveer',
            options: [
              {
                mask: 'a',
                label:
                  'Thought leader zijn: met vernieuwing een unieke bijdrage leveren ',
              },
              {
                mask: 'b',
                label: 'Zorgen voor een betere en eerlijke wereld ',
              },
              {
                mask: 'c',
                label: 'Partijen verbinden want samen is het beter dan alleen ',
              },
              { mask: 'd', label: 'De beste in zijn soort zijn ' },
              { mask: 'e', label: 'De wereld verlossen van al het lijden' },
              {
                mask: 'f',
                label:
                  'De best denkbare prestatie te leveren die mensen nooit vergeten',
              },
            ],
          },
          {
            question: 'Hoe gaat de organisatie om met kansen?',
            header: 'Kansen ',
            options: [
              {
                mask: 'a',
                label:
                  'Even kijken of deze kans past in onze visie, langere termijn propositie etc.',
              },
              {
                mask: 'b',
                label:
                  'Toe maar, als ‘t moet doen we het wel. Misschien dat we ons dan in de kijker spelen...',
              },
              {
                mask: 'c',
                label:
                  'Yes, weer iets nieuws om samen in te duiken! Laten we snel beginnen en improviseren',
              },
              {
                mask: 'd',
                label:
                  'Yes! Natuurlijk gaan we daar een succes van maken, niet in de lengte dan in de breedte',
              },
              {
                mask: 'e',
                label:
                  'Nog meer kansen? Het kan eigenlijk niet meer, maar vooruit dan. Nee is ook geen optie ',
              },
              {
                mask: 'f',
                label:
                  'We pakken de kans als we bijna zeker weten dat we het ook daadwerkelijk kunnen waarmaken',
              },
            ],
          },
          {
            question: 'Hoe worden successen gevierd in deze organisatie?',
            header: 'Successen vieren',
            options: [
              {
                mask: 'a',
                label:
                  'Een korte uiteenzetting van het succes in de nieuwsbrief ',
              },
              {
                mask: 'b',
                label:
                  'Onze Chief Happiness Officer zorgt dat alles wat te vieren is op het podium komt ',
              },
              {
                mask: 'c',
                label:
                  'Gezamenlijke rituelen zoals een wekelijkse borrel en verjaardagen ',
              },
              {
                mask: 'd',
                label:
                  'Medewerker van de maand: prestaties moeten op een voetstuk ',
              },
              {
                mask: 'e',
                label: 'Er valt niks te vieren zolang er nog zoveel te doen is',
              },
              {
                mask: 'f',
                label:
                  'Op de maandelijks drukbezochte borrel wordt flink uitgepakt',
              },
            ],
          },
          {
            question:
              'Wat is de tol die mensen betalen om bij dit bedrijf te werken?',
            header: 'Frustratie',
            options: [
              {
                mask: 'a',
                label:
                  'Ideeën komen niet van de grond, het energieniveau is laag ',
              },
              {
                mask: 'b',
                label:
                  'Er is een collectief Calimero gevoel: zoveel gegeven en nog geen podiumplek ',
              },
              {
                mask: 'c',
                label:
                  'Er worden nauwelijks besluiten genomen, dezelfde onderwerpen komen steeds weer terug',
              },
              {
                mask: 'd',
                label:
                  'Feedback heeft niet zoveel zin, want er is niet echt een leervraag . Twijfel ontbreekt',
              },
              {
                mask: 'e',
                label:
                  'Je kunt je hier niet zo gemakkelijk even ontspannen, er is altijd weer meer werk',
              },
              {
                mask: 'f',
                label:
                  'Als je hier faalt lig je eruit, dus er is veel druk om te moeten excelleren ',
              },
            ],
          },
          {
            question:
              'Hoe kijken mensen naar hun organisatie, vergeleken met de buitenwereld?',
            header: 'Zelfbeeld ',
            options: [
              {
                mask: 'a',
                label:
                  'Thank god for us: zonder onze denkkracht zou het niks worden ',
              },
              {
                mask: 'b',
                label:
                  'Iemand moet dit doen en er zijn er ook maar weinig die het echt kunnen',
              },
              {
                mask: 'c',
                label:
                  'Zo fijn dat ik onderdeel van deze familie ben, ik krijg het nergens zo goed als hier ',
              },
              {
                mask: 'd',
                label:
                  'Wij zijn vele malen beter, en we gaan ze een poepie laten ruiken',
              },
              {
                mask: 'e',
                label:
                  'Iedereen werkt hier voor een reden, we hebben allemaal een hoger doel',
              },
              {
                mask: 'f',
                label:
                  'Wij zijn het top-tier bedrijf waar alleen de beste en meest bijzondere mensen kunnen werken',
              },
            ],
          },
          {
            question: 'Welk appel doet deze organisatie op medewerkers?',
            header: 'Appel',
            options: [
              {
                mask: 'a',
                label:
                  'Blijf op het niveau van ideeën en ga niet naar de emotionele laag',
              },
              {
                mask: 'b',
                label: 'Heb aandacht voor de ander, wij zorgen voor elkaar ',
              },
              {
                mask: 'c',
                label: 'Wees gewoon lekker jezelf, fouten maken mag',
              },
              { mask: 'd', label: 'Wees net zo sterk als wij' },
              { mask: 'e', label: 'Blijf volhouden' },
              {
                mask: 'f',
                label:
                  'Verberg je fouten en twijfels, er is hier geen ruimte voor onzekerheid',
              },
            ],
          },
        ],
      },
      {
        name: 'personal',
        language: 'en',
        introductionStart: require('@/assets/html/intro-personal-start.html'),
        introductionEnd: require('@/assets/html/intro-personal-end.html'),
        questions: [
          {
            question: 'What motivated you when you were a child?',
            header: ' ',
            options: [
              { mask: 'a', label: 'Dreaming away in my fantasy world' },
              { mask: 'c', label: "Being everyone's best friend" },
              { mask: 'd', label: 'Being the leader of the pack' },
              {
                mask: 'e',
                label: 'Being just a little bit wiser than my peers',
              },
              { mask: 'f', label: "Exceeding everyone's expectations" },
            ],
          },
          {
            question: 'What is not so easy for you to do?',
            header: '',
            options: [
              { mask: 'a', label: 'To chitchat in a room full of people' },
              { mask: 'c', label: 'To make difficult choices' },
              { mask: 'd', label: 'To really trust others' },
              { mask: 'e', label: 'To celebrate my own successes' },
              { mask: 'f', label: 'To lower my standards' },
            ],
          },
          {
            question: 'What is your biggest strength?',
            header: '',
            options: [
              { mask: 'a', label: 'To zoom out and see the bigger picture' },
              { mask: 'c', label: 'To get along with everyone' },
              { mask: 'd', label: 'To challenge the status quo' },
              { mask: 'e', label: 'To be trustworthy' },
              { mask: 'f', label: 'To make it happen' },
            ],
          },
          {
            question: 'What are you essentially afraid of?',
            header: '',
            options: [
              { mask: 'a', label: 'To be misunderstood' },
              { mask: 'c', label: "To hurt someone's feelings" },
              { mask: 'd', label: 'To show vulnerability' },
              { mask: 'e', label: 'To be insignificant' },
              { mask: 'f', label: 'To make mistakes' },
            ],
          },
          {
            question: 'What do you do more of compared to others?',
            header: '',
            options: [
              { mask: 'a', label: 'I think more out-of-the-box' },
              {
                mask: 'c',
                label: 'I create the safe haven, where everyone feels welcome',
              },
              { mask: 'd', label: 'I use my power to achieve what I want' },
              { mask: 'e', label: 'I feel more responsible' },
              { mask: 'f', label: 'I have higher standards' },
            ],
          },
          {
            question: 'How do other people see you?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'They find me inspiring and independent, yet a little bit distant',
              },
              {
                mask: 'c',
                label:
                  "They feel seen and understood by me, but they don't know what I stand for",
              },
              {
                mask: 'd',
                label:
                  'They find me strong and fearless, and also a bit insensitive',
              },
              {
                mask: 'e',
                label:
                  'They trust me and rely on me, but there is not a lot of room for fun',
              },
              {
                mask: 'f',
                label:
                  "They trust it's in good hands, but they also find me demanding",
              },
            ],
          },
          {
            question: 'What is your default response to stress?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'I withdraw and analyse the situation from a safe distance',
              },
              {
                mask: 'c',
                label: 'I accommodate and try to make everyone happy',
              },
              {
                mask: 'd',
                label:
                  'I make up a battle plan and push others to their limits',
              },
              {
                mask: 'e',
                label: 'I work day and night to make sure all the work is done',
              },
              {
                mask: 'f',
                label:
                  'I hold on to my standards but I get controlling and directive',
              },
            ],
          },
          {
            question: 'What do people admire most in you?',
            header: '',
            options: [
              { mask: 'a', label: 'That I am a creative thinker' },
              { mask: 'c', label: 'That I am a collaborator' },
              { mask: 'd', label: 'That I am a self-starter' },
              { mask: 'e', label: 'That I can work hard and long' },
              { mask: 'f', label: "That I'm in control" },
            ],
          },
          {
            question: 'Every now and then people will say you are…',
            header: '',
            options: [
              { mask: 'a', label: 'Too detached' },
              { mask: 'c', label: 'Too soft' },
              { mask: 'd', label: 'Too blunt' },
              { mask: 'e', label: 'Too serious' },
              { mask: 'f', label: 'Too critical' },
            ],
          },
          {
            question: 'What does your inner critic say?',
            header: '',
            options: [
              { mask: 'a', label: 'Nobody really understands the way I think' },
              { mask: 'c', label: "My opinion doesn't count" },
              { mask: 'd', label: "I can't show my weakness" },
              { mask: 'e', label: 'I must carry on, there is no other way' },
              { mask: 'f', label: "I'm only as good as my last success" },
            ],
          },
          {
            question: 'What can you sometimes overdo?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'I sometimes lose sight of the practical side of things',
              },
              { mask: 'c', label: 'I can be too much of a pleaser' },
              { mask: 'd', label: 'I intimidate people' },
              { mask: 'e', label: 'I carry more load than is good for me' },
              { mask: 'f', label: 'I can be real control freak' },
            ],
          },
          {
            question: 'How do you justify your own disfunctional behaviour?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'I know I use too many words, but I just need to explain it better',
              },
              {
                mask: 'c',
                label:
                  "I know I'm a chameleon, but I just want to keep harmony",
              },
              {
                mask: 'd',
                label: "I know I'm direct, but the goal justifies the means",
              },
              {
                mask: 'e',
                label:
                  "I know I suck in setting boundaries, but if I don't do it, no one will",
              },
              {
                mask: 'f',
                label: "I know I'm pushy, but I just want to get it right",
              },
            ],
          },
          {
            question: 'How do you respond to a conflict/argument?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Arguing with me is more like an intellectual debate',
              },
              {
                mask: 'c',
                label: "I don't typically argue, as I prefer harmony",
              },
              {
                mask: 'd',
                label: 'If people push me too far, I will tighten the reins',
              },
              {
                mask: 'e',
                label: 'I swallow my frustration and silently plow on...',
              },
              {
                mask: 'f',
                label:
                  'I stay cool and on top of things, in control of the argument',
              },
            ],
          },
          {
            question: 'When do you really feel like you have found your tribe?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'In creative settings, where ideas and ideals are highly valued',
              },
              {
                mask: 'c',
                label:
                  'In informal settings, where everybody knows each other well',
              },
              {
                mask: 'd',
                label: "In competitive settings, where it's 'us against them'",
              },
              {
                mask: 'e',
                label: 'In dependable settings, where people really need me',
              },
              {
                mask: 'f',
                label:
                  "In high performing settings, where there's a lot of energy and drive",
              },
            ],
          },
        ],
      },
      {
        name: 'organisation',
        language: 'en',
        introductionStart: require('@/assets/html/intro-organisation-start.html'),
        introductionEnd: require('@/assets/html/intro-organisation-end.html'),
        questions: [
          {
            question: 'When is this organisation at its very best?',
            header: 'Best Moment',
            options: [
              {
                mask: 'a',
                label:
                  'In the brainstorming phase where everything is still possible ',
              },
              {
                mask: 'b',
                label: 'In situations when everyone needs us and we matter',
              },
              { mask: 'c', label: 'During social events' },
              { mask: 'd', label: 'When speed and decisiveness are needed ' },
              {
                mask: 'e',
                label:
                  'When it is all about higher values such as sense of purpose, selflessness and integrity',
              },
              {
                mask: 'f',
                label:
                  'When everything has to be perfect, when details matter and nothing can go wrong',
              },
            ],
          },
          {
            question:
              'What is essentially the greatest strength of this organisation?',
            header: 'Strength',
            options: [
              { mask: 'a', label: 'An autonomous view on complex issues' },
              {
                mask: 'b',
                label:
                  'Being able to make others feel good, make them happy or being able to help ',
              },
              {
                mask: 'c',
                label: 'The warmest and most pleasant company to work for',
              },
              { mask: 'd', label: 'Being the very best' },
              { mask: 'e', label: 'Do-gooders' },
              {
                mask: 'f',
                label: 'Impressing the world with their achievements',
              },
            ],
          },
          {
            question: 'What makes people in this organisation really happy?',
            header: 'Energy',
            options: [
              {
                mask: 'a',
                label: 'Confused complexity, the more complicated the better',
              },
              {
                mask: 'b',
                label: 'Acknowledgement for the meaningful work we do ',
              },
              { mask: 'c', label: 'Getting things done together' },
              { mask: 'd', label: 'Beating the competition' },
              {
                mask: 'e',
                label:
                  'Putting your heart and soul into what matters to society ',
              },
              {
                mask: 'f',
                label: 'The kick of a flawless and amazing execution',
              },
            ],
          },
          {
            question: 'What motives drive people working in this organisation?',
            header: 'Drivers',
            options: [
              {
                mask: 'a',
                label:
                  'Innovative ideas and innovations that lead to a better future',
              },
              {
                mask: 'b',
                label: 'Creating a fair world where everyone has a position',
              },
              {
                mask: 'c',
                label:
                  'Being allowed to be yourself, it is okay to be insecure',
              },
              {
                mask: 'd',
                label: 'Being able to influence and make an impact ',
              },
              { mask: 'e', label: 'Being able to do meaningful work' },
              {
                mask: 'f',
                label:
                  'Wanting to meet the high standards, and preferably even to exceed them',
              },
            ],
          },
          {
            question:
              'What type of people become leaders in this organisation?',
            header: 'Leadership',
            options: [
              {
                mask: 'a',
                label:
                  "The people who have the most knowledge ('managers' without substance will not be accepted)",
              },
              { mask: 'b', label: 'People who like to be indispensable' },
              {
                mask: 'c',
                label:
                  'A leader should not be too dominant, but at the same time expectations are high',
              },
              {
                mask: 'd',
                label:
                  'There is admiration for leaders but also distrust and fear',
              },
              {
                mask: 'e',
                label: 'Leadership is a matter of responsibility and sacrifice',
              },
              {
                mask: 'f',
                label: 'Organisational talents that leave nothing to chance',
              },
            ],
          },
          {
            question: 'What is the atmosphere like in the office?',
            header: 'Social Interaction',
            options: [
              {
                mask: 'a',
                label:
                  'All conversations are substantive, we are a bunch of professional idiots',
              },
              {
                mask: 'b',
                label:
                  'We are always busy, but we do not always deliver concrete results',
              },
              {
                mask: 'c',
                label:
                  'It is always a pleasant chaos here, with plenty of shared moments',
              },
              {
                mask: 'd',
                label:
                  'We are optimistic and solution-oriented, we like challenges and adventures',
              },
              {
                mask: 'e',
                label:
                  'We are serious and passionate, there is a sense of ‘we must’, limited lightness and fun',
              },
              {
                mask: 'f',
                label:
                  'Everything has been thought over and everything is where it should be, we work very hard',
              },
            ],
          },
          {
            question: 'How do customers experience this organisation?',
            header: 'Impact on Others',
            options: [
              {
                mask: 'a',
                label:
                  'Customers tend to look up to us, this is where the smartest people work',
              },
              {
                mask: 'b',
                label: "We always say 'yes' (and thus always get snowed under)",
              },
              {
                mask: 'c',
                label:
                  'This club has a strong goodwill factor, they almost feel like friends',
              },
              {
                mask: 'd',
                label:
                  'If you need a Pitbull, this is the organisation for you',
              },
              {
                mask: 'e',
                label:
                  'You can always rely on this organisation, they never let you down',
              },
              {
                mask: 'f',
                label:
                  'They always have their affairs in order, one never has to worry about anything',
              },
            ],
          },
          {
            question: 'Why is it pleasant to work for this company?',
            header: 'Positive Effect on Others',
            options: [
              {
                mask: 'a',
                label:
                  'Creativity and ideas really come to life, that is what it is all about',
              },
              {
                mask: 'b',
                label:
                  'There is a caring atmosphere, where there is always an eye for people',
              },
              {
                mask: 'c',
                label:
                  'You can really be yourself, it almost feels like a family',
              },
              {
                mask: 'd',
                label:
                  'Falling short is fine, as long as you learn from it. It is work hard, play hard',
              },
              {
                mask: 'e',
                label: 'You can proudly present this at the gates of Heaven',
              },
              { mask: 'f', label: 'This is a prestigious company to work for' },
            ],
          },
          {
            question: 'What is the hassle mostly about in this organisation?',
            header: 'Negative Effect on Others',
            options: [
              {
                mask: 'a',
                label:
                  'What we exchange are opinions, what we keep to ourselves are mutual frustrations',
              },
              {
                mask: 'b',
                label:
                  'We are so busy with what the world asks of us that we forget each other',
              },
              {
                mask: 'c',
                label:
                  'If there is no harmony, panic and arguments arise, which we find hard to cope with',
              },
              {
                mask: 'd',
                label:
                  'The sensitive types have a hard time here, this is not for the fainthearted',
              },
              {
                mask: 'e',
                label:
                  'We plod on, but are never done. Our pace is steady, but also slow',
              },
              {
                mask: 'f',
                label:
                  'Gossiping about people who do not meet the (high) standards',
              },
            ],
          },
          {
            question: 'What happens if there is a conflict?',
            header: 'Conflictmanagement',
            options: [
              {
                mask: 'a',
                label: 'Conflicts resemble mental chess games or are avoided',
              },
              {
                mask: 'b',
                label:
                  'We make issues bigger than they are, which feels unfair, an emotional swamp',
              },
              {
                mask: 'c',
                label: 'Emerging conflicts are bypassed or appeased',
              },
              {
                mask: 'd',
                label: 'Conflict is just part of life. We fight openly',
              },
              {
                mask: 'e',
                label:
                  'We have opinions but swallow them and go back to business',
              },
              {
                mask: 'f',
                label:
                  'An endless stream of words or e-mails in which the right viewpoint is irrefutably demonstrated',
              },
            ],
          },
          {
            question: 'How are power and hierarchy dealt with??',
            header: 'Power',
            options: [
              {
                mask: 'a',
                label: 'Knowledge is power, hierarchy is irrelevant',
              },
              {
                mask: 'b',
                label:
                  'Major appeal on the MT for guidance and coaching, while they are also being blamed for not getting it right',
              },
              {
                mask: 'c',
                label:
                  'Power is in the people, we go for it together. Power is not an attractive word',
              },
              {
                mask: 'd',
                label:
                  "Power is glorified, it's up or out. We have no space for losers here",
              },
              {
                mask: 'e',
                label: 'Standards and values are monitored by leadership',
              },
              {
                mask: 'f',
                label:
                  'Our organisation accepts servant leadership with a strong moral anchor',
              },
            ],
          },
          {
            question:
              'What are the deeper convictions (scripts) that live in this organisation?',
            header: 'Scripts',
            options: [
              {
                mask: 'a',
                label: 'We earn a place in the world only if we are brilliant ',
              },
              {
                mask: 'b',
                label:
                  'If we take that extra step we might get a pat on the back',
              },
              {
                mask: 'c',
                label:
                  'Together we are strong, and participating is more important than winning ',
              },
              {
                mask: 'd',
                label:
                  'We have no weaknesses, and unless there is a huge crisis, we have nothing to learn',
              },
              {
                mask: 'e',
                label:
                  'We must persevere and persevere, that is what it takes and what ultimately helps',
              },
              {
                mask: 'f',
                label:
                  "We don't leave anything to chance, that's when it all goes wrong",
              },
            ],
          },
          {
            question:
              'What is seen as the biggest threat in this organisation?',
            header: 'Deepest Fear',
            options: [
              { mask: 'a', label: 'Inhibition of creativity and innovation' },
              { mask: 'b', label: 'That what we do, does not matter' },
              {
                mask: 'c',
                label:
                  'If harmony is disturbed, we are afraid that the system will fall apart',
              },
              { mask: 'd', label: 'Being defeated by the competition' },
              { mask: 'e', label: 'That all our hard labour got us nowhere' },
              {
                mask: 'f',
                label:
                  'That we made a mistake and the customer is not satisfied',
              },
            ],
          },
          {
            question: 'What is the original purpose of this organisation?',
            header: 'Initial Driver',
            options: [
              {
                mask: 'a',
                label:
                  'Being a thought leader: making a unique contribution through innovation ',
              },
              { mask: 'b', label: 'Caring for a better and fair world ' },
              {
                mask: 'c',
                label:
                  'Connecting parties, because together is better than alone ',
              },
              { mask: 'd', label: 'Being the best of its kind ' },
              { mask: 'e', label: 'Redeeming the world from all suffering' },
              {
                mask: 'f',
                label:
                  'To deliver the best performance imaginable that people will never forget',
              },
            ],
          },
          {
            question: 'How does the organisation deal with opportunities?',
            header: 'Opportunties ',
            options: [
              {
                mask: 'a',
                label:
                  'Checking whether this opportunity fits into our vision, long-term proposition, etc.',
              },
              {
                mask: 'b',
                label:
                  "We'll do it if we have to. Maybe it will provide us with a place in the spotlight....",
              },
              {
                mask: 'c',
                label:
                  "Yes, something new to dive into together! Let's start immediately and improvise",
              },
              {
                mask: 'd',
                label: 'Yes! Of course we will make this a success, regardless',
              },
              {
                mask: 'e',
                label:
                  "More chances? It is actually not an option anymore, but let's go for it. No is not an option either",
              },
              {
                mask: 'f',
                label:
                  'We take the chance when we are almost certain that we can be successful ',
              },
            ],
          },
          {
            question: 'How are successes celebrated in this organisation?',
            header: 'Celebrating Success',
            options: [
              {
                mask: 'a',
                label: 'A brief outline of the success in the newsletter ',
              },
              {
                mask: 'b',
                label:
                  'The Chief Happiness Officer ensures that everything that deserves to be celebrated is on stage ',
              },
              {
                mask: 'c',
                label:
                  'Joint rituals such as weekly drinks and birthday celebrations ',
              },
              {
                mask: 'd',
                label:
                  'Employee of the month: achievements are put on a pedestal ',
              },
              {
                mask: 'e',
                label:
                  'There is nothing to celebrate while there is still so much to do',
              },
              {
                mask: 'f',
                label: 'The monthly get-together with good wine and snacks ',
              },
            ],
          },
          {
            question:
              'What is the toll that people pay to work for this company?',
            header: 'Frustration',
            options: [
              {
                mask: 'a',
                label:
                  'Ideas do not get off the ground, energy levels are low ',
              },
              {
                mask: 'b',
                label:
                  'There is a collective sense of ‘Calimero’: so much effort and still no podium ',
              },
              {
                mask: 'c',
                label:
                  'Hardly any real decisions are taken, the same topics keep coming up again and again',
              },
              {
                mask: 'd',
                label:
                  'Feedback is not very useful, because there is no real learning question.  There is no doubt',
              },
              {
                mask: 'e',
                label:
                  'It is not easy to relax here, there is always more work to be done',
              },
              {
                mask: 'f',
                label:
                  'If you fail here you are kicked out, so there is extensive pressure to excel',
              },
            ],
          },
          {
            question:
              'How do people view their organisation compared to the outside world?',
            header: 'Self-Esteem',
            options: [
              {
                mask: 'a',
                label:
                  'Thank god for us: without our brainpower we would be nowhere ',
              },
              {
                mask: 'b',
                label:
                  'Someone has to do it and there are very few who have the abilities ',
              },
              {
                mask: 'c',
                label:
                  'So nice to be part of this family, no place would be as good as here ',
              },
              {
                mask: 'd',
                label: 'We are so much better, we will blow others away',
              },
              {
                mask: 'e',
                label:
                  'Everyone works here for a reason, we all have a higher purpose',
              },
              {
                mask: 'f',
                label:
                  'We are the top-tier company where only the best and the most remarkable can work',
              },
            ],
          },
          {
            question: 'What appeal does this organisation make to employees?',
            header: 'Appeal',
            options: [
              {
                mask: 'a',
                label:
                  'Stay on the level of ideas and do not go to the emotional layer',
              },
              {
                mask: 'b',
                label: 'Pay attention to others, we take care of one another ',
              },
              {
                mask: 'c',
                label: 'Just be yourself, making mistakes is allowed',
              },
              { mask: 'd', label: 'Be as strong as we are' },
              { mask: 'e', label: 'Persevere' },
              {
                mask: 'f',
                label:
                  'Hide your mistakes and doubts, there is no room for insecurity here',
              },
            ],
          },
        ],
      },
      {
        name: 'egotypescan',
        language: 'nl',
        introductionStart: require('@/assets/html/intro-egotypescan-start.html'),
        introductionEnd: require('@/assets/html/intro-egotypescan-end.html'),
        resultText: require('@/assets/html/results-self-nl.html'),
        mailtemplate: 'finished_self_nl',
        isForOther: false,
        // showEndscreenResult: false,
        mailToSubject: true,
        mailToOwner: false,
        allPointsMandatory: true,
        questions: [
          {
            question: 'Wat is jouw grootste kwaliteit?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Het grotere plaatje zien',
              },
              // {
              //   mask: 'b',
              //   label: 'Als ik iemand blij heb gemaakt met mijn hulp',
              // },
              {
                mask: 'c',
                label: 'Aanvoelen wat anderen nodig hebben',
              },
              {
                mask: 'd',
                label: 'De status-quo uitdagen',
              },
              {
                mask: 'e',
                label: 'Behulpzaam zijn',
              },
              {
                mask: 'f',
                label: 'Iets tot in de puntjes afmaken',
              },
            ],
          },
          {
            question: 'Hoe maak je contact met mensen?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik voer een gesprek over de inhoud',
              },
              // { mask: 'b', label: 'Dat ik altijd voor iedereen klaar sta' },
              { mask: 'c', label: 'Ik maak met iedereen vrienden' },
              {
                mask: 'd',
                label: 'Ik maak iedereen aan het lachen  ',
              },
              { mask: 'e', label: 'Ik start een diep gesprek over het leven ' },
              {
                mask: 'f',
                label: 'Ik wind iedereen om mijn vinger ',
              },
            ],
          },
          {
            question: 'Hoe zien andere mensen jou?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'Ze bewonderen mijn onafhankelijkheid, maar vinden me ook ongrijpbaar',
              },
              // { mask: 'b', label: 'Als geven en nemen in balans is' },
              {
                mask: 'c',
                label:
                  'Ze voelen zich begrepen, maar weten niet precies wat er in mij om gaat',
              },
              {
                mask: 'd',
                label: 'Ze vinden me sterk, maar ook een beetje hard',
              },
              {
                mask: 'e',
                label:
                  'Ze kunnen op me bouwen, maar er is niet veel ruimte voor plezier',
              },
              {
                mask: 'f',
                label: 'Ze vinden me indrukwekkend, maar ook veeleisend',
              },
            ],
          },
          {
            question: 'Wat gaat jou niet zo makkelijk af?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Over koetjes en kalfjes kletsen op een drukke borrel',
              },
              // {
              //   mask: 'b',
              //   label: 'Ik hoop dat ik kan bijdragen aan meer rechtvaardigheid',
              // },
              {
                mask: 'c',
                label: "Me 'dingen niet zo aantrekken'",
              },
              {
                mask: 'd',
                label: 'Afhankelijk zijn van anderen',
              },
              { mask: 'e', label: 'De boel de boel laten' },
              {
                mask: 'f',
                label: 'Mijn hoge standaarden loslaten',
              },
            ],
          },
          {
            question: 'Hoe reageer je onder druk?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik keer in mezelf en stop met communiceren',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik ben warm, attent en rechtvaardig, ik weet precies wat iedereen nodig heeft',
              // },
              {
                mask: 'c',
                label: 'Ik ga koortsachtig aan de slag maar maak niks af',
              },
              {
                mask: 'd',
                label:
                  'Ik neem de touwtjes stevig in handen en vertel iedereen wat ie moet doen ',
              },
              {
                mask: 'e',
                label: 'Ik ga altijd maar door, ten koste van mezelf',
              },
              {
                mask: 'f',
                label:
                  'Ik zit er bovenop en push mezelf - en anderen - tot het uiterste',
              },
            ],
          },
          {
            question: 'Welke plek heb jij in een groep?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik hoor er net niet helemaal bij',
              },
              // {
              //   mask: 'b',
              //   label: 'Vriendelijk en vrijgevig, maar toch ook veeleisend',
              // },
              {
                mask: 'c',
                label: 'Ik zorg voor de verbinding  ',
              },
              {
                mask: 'd',
                label: 'Ik heb vaak de leiding ',
              },
              {
                mask: 'e',
                label: 'Ik maak me dienstbaar',
              },
              {
                mask: 'f',
                label: 'Ik zorg dat alles volgens plan gaat',
              },
            ],
          },
          {
            question: 'Waar ben je beter in dan anderen?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Out-of-the-box denken',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Dat ik met complimenten strooi en nooit iemands verjaardag vergeet',
              // },
              {
                mask: 'c',
                label: 'Me in anderen verplaatsen',
              },
              {
                mask: 'd',
                label: 'Initiatief nemen en doorpakken',
              },
              {
                mask: 'e',
                label: 'Sterke schouders bieden om op te leunen',
              },
              {
                mask: 'f',
                label: 'Iets tot in de puntjes afmaken ',
              },
            ],
          },
          {
            question: 'Waarin kan je soms doorslaan?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik maak dingen te ingewikkeld ',
              },
              // {
              //   mask: 'b',
              //   label:
              //     "Ik kan soms een beetje 'needy' overkomen, het is eigenlijk nooit genoeg",
              // },
              {
                mask: 'c',
                label: 'Ik cijfer mezelf teveel weg ',
              },
              {
                mask: 'd',
                label: 'Ik drijf mijn zin teveel door ',
              },
              {
                mask: 'e',
                label: "Ik neem teveel hooi op m'n vork ",
              },
              {
                mask: 'f',
                label: 'Ik kan echt een controlfreak zijn',
              },
            ],
          },
          {
            question: 'Wat bewonderen anderen in jou?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Dat ik creatief ben ',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik voel me vaak onmachtig en gefrustreerd, het leven voelt vaak oneerlijk',
              // },
              {
                mask: 'c',
                label: 'Dat ik een bruggenbouwer ben',
              },
              {
                mask: 'd',
                label: 'Dat ik daadkrachtig ben  ',
              },
              {
                mask: 'e',
                label: 'Dat ik een volhouder ben',
              },
              {
                mask: 'f',
                label: 'Dat ik veel kan bereiken',
              },
            ],
          },
          {
            question: 'Wat zegt jouw innerlijke criticus?',
            header: '',
            options: [
              {
                mask: 'a',
                label: '"Niemand begrijpt me écht"',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik weet dat ik een kameleon ben, maar ik wil graag de harmonie bewaren',
              // },
              {
                mask: 'c',
                label: '"Mijn mening doet er niet toe"',
              },
              {
                mask: 'd',
                label: '"Ik mag niet zwak zijn"',
              },
              {
                mask: 'e',
                label: '"Niet klagen maar dragen" ',
              },
              {
                mask: 'f',
                label: '"Het is nooit goed genoeg"',
              },
            ],
          },
          {
            question: 'Hoe reageer je op een conflict?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik maak er een intellectueel debat van',
              },
              // { mask: 'b', label: 'Dat er niet genoeg is voor mij ' },
              {
                mask: 'c',
                label: 'Ik maak het heel emotioneel ',
              },
              {
                mask: 'd',
                label: 'Ik ga de strijd aan',
              },
              {
                mask: 'e',
                label: 'Ik slik mijn frustratie weg ',
              },
              {
                mask: 'f',
                label: 'Ik word koel en afstandelijk',
              },
            ],
          },
          {
            question: 'Zo nu en dan zeggen mensen dat ik...',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'te afstandelijk ben',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label: 'teveel een pleaser ben',
              },
              {
                mask: 'd',
                label: 'te bot ben',
              },
              {
                mask: 'e',
                label: 'te serieus ben',
              },
              {
                mask: 'f',
                label: 'te kritisch ben',
              },
            ],
          },
          {
            question: 'Hoe rechtvaardig je je eigen gedrag?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'Ik weet dat ik veel woorden gebruik, maar anders snappen ze het niet',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label:
                  'Ik weet dat ik een kameleon ben, maar ik wil het de ander naar de zin maken   ',
              },
              {
                mask: 'd',
                label:
                  'Ik weet dat ik direct ben, maar het doel heiligt de middelen',
              },
              {
                mask: 'e',
                label:
                  'Ik weet dat ik slecht grenzen kan stellen, maar iemand moet het toch doen?',
              },
              {
                mask: 'f',
                label:
                  'Ik weet dat ik zit te micromanagen, maar ik wil gewoon dat het goed gaat',
              },
            ],
          },
          {
            question: 'Hoe ga je om met kritiek?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  "Ik schiet in m'n hoofd, pas na een paar dagen komt 'ie binnen",
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label: 'Ik raak in paniek en voel me gekwetst en afgewezen ',
              },
              {
                mask: 'd',
                label:
                  'Ik laat het niet echt binnenkomen en mep gewoon meteen terug',
              },
              {
                mask: 'e',
                label:
                  'Het voelt zo oneerlijk... zie je niet hoe erg ik mijn best heb gedaan?',
              },
              {
                mask: 'f',
                label:
                  'Kritiek komt bij mij keihard binnen, het voelt als falen',
              },
            ],
          },
          {
            question: 'Hoe ga je om met vertrouwen? ',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik vertrouw de wereld niet echt',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label:
                  'Ik heb veel vertrouwen in anderen, maar niet zo veel zelfvertrouwen',
              },
              {
                mask: 'd',
                label: 'Ik vertrouw iedereen - en eigenlijk niemand',
              },
              {
                mask: 'e',
                label:
                  'Ik wil dat iedereen mij in vertrouwen neemt (maar liever niet andersom)',
              },
              {
                mask: 'f',
                label:
                  'Ik straal zelfvertrouwen uit, maar laat niemand mijn imperfecties zien',
              },
            ],
          },
          {
            question: 'Waar ben je het allerbangst voor?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Mijn onafhankelijkheid kwijtraken',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label: 'In de steek gelaten worden',
              },
              {
                mask: 'd',
                label: 'Kwetsbaar zijn',
              },
              {
                mask: 'e',
                label: 'Niet van betekenis zijn',
              },
              {
                mask: 'f',
                label: 'Afgewezen worden',
              },
            ],
          },
          {
            question: 'Hoe uit woede zich bij jou?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  "Ik 'implodeer': ik voel veel spanning in mijn lijf en ga een beetje op slot",
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label:
                  'Als ik boos ben maak ik dingen groot en word emotioneel',
              },
              {
                mask: 'd',
                label: "Ik 'explodeer': als ik boos ben weet iedereen het",
              },
              {
                mask: 'e',
                label:
                  'Ik krop mijn woede op en van tijd tot tijd barst de bom',
              },
              {
                mask: 'f',
                label:
                  'Ik word een beetje koel en streng en ik voel me ietwat verheven boven de ander',
              },
            ],
          },
          {
            question: 'Wat doet angst met jou? ',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Ik kom in een mindfuck en zie gevaren die er niet zijn',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label:
                  'Ik bel iedereen om bevestiging te krijgen dat het goed komt',
              },
              {
                mask: 'd',
                label: 'Ik ontken dat ik angst heb, ook aan mijzelf',
              },
              {
                mask: 'e',
                label: 'Ik lijd in stilte en zie even geen uitweg',
              },
              {
                mask: 'f',
                label: 'Ik bevries omdat ik geen controle meer voel',
              },
            ],
          },
        ],
      },
      {
        name: 'egotypescan-other',
        language: 'nl',
        mailtemplate: 'finished_other_nl',
        isForOther: true,
        // showEndscreenResult: false,
        mailToSubject: true,
        mailToOwner: false,
        allPointsMandatory:false,
        introductionStart: require('@/assets/html/intro-egotypescan-other-start.html'),
        introductionEnd: require('@/assets/html/intro-egotypescan-other-end.html'),
        preStart: require('@/assets/html/prestart-egotypescan-other.html'),
        resultText: require('@/assets/html/results-other-nl.html'),
        questions: [
          {
            question: 'Wat is de grootste kwaliteit van #naam#?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Het grotere plaatje zien',
              },
              // {
              //   mask: 'b',
              //   label: 'Als ik iemand blij heb gemaakt met mijn hulp',
              // },
              {
                mask: 'c',
                label: 'Aanvoelen wat anderen nodig hebben',
              },
              {
                mask: 'd',
                label: 'De status-quo uitdagen',
              },
              {
                mask: 'e',
                label: 'Behulpzaam zijn',
              },
              {
                mask: 'f',
                label: 'Het niveau naar een hoger plan tillen',
              },
            ],
          },
          {
            question: 'Hoe maakt #naam# contact met anderen?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Door een een gesprek te voeren over de inhoud',
              },
              // { mask: 'b', label: 'Dat ik altijd voor iedereen klaar sta' },
              { mask: 'c', label: 'Door met iedereen vrienden te maken' },
              {
                mask: 'd',
                label: 'Door iedereen aan het lachen te maken',
              },
              {
                mask: 'e',
                label: 'Door een diep gesprek over het leven te starten',
              },
              {
                mask: 'f',
                label: 'Door iedereen om zijn/haar vinger te winden',
              },
            ],
          },
          {
            question: 'Hoe ik #naam# zie.',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'ik bewonder de autonomie van #naam#, maar vind hem/haar ook ongrijpbaar',
              },
              // { mask: 'b', label: 'Als geven en nemen in balans is' },
              {
                mask: 'c',
                label:
                  'ik voel me begrepen, maar weet niet precies wat er in #naam# om gaat',
              },
              {
                mask: 'd',
                label: 'ik vind #naam# sterk, maar ook een beetje hard',
              },
              {
                mask: 'e',
                label:
                  'ik kan op #naam# bouwen, maar er is niet veel ruimte voor plezier',
              },
              {
                mask: 'f',
                label: 'ik vind #naam# indrukwekkend, maar ook veeleisend',
              },
            ],
          },
          {
            question: 'Wat gaat #naam# niet zo makkelijk af?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Over koetjes en kalfjes kletsen op een drukke borrel',
              },
              // {
              //   mask: 'b',
              //   label: 'Ik hoop dat ik kan bijdragen aan meer rechtvaardigheid',
              // },
              {
                mask: 'c',
                label: "Zich 'dingen niet zo aantrekken'",
              },
              {
                mask: 'd',
                label: 'Afhankelijk zijn van anderen',
              },
              { mask: 'e', label: 'De boel de boel laten' },
              {
                mask: 'f',
                label: 'Loslaten van de hoge standaarden',
              },
            ],
          },
          {
            question: 'Hoe reageert #naam# onder druk?',
            header: '',
            options: [
              {
                mask: 'a',
                label: '#naam# keert in zichzelf en stopt met communiceren',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik ben warm, attent en rechtvaardig, ik weet precies wat iedereen nodig heeft',
              // },
              {
                mask: 'c',
                label:
                  '#naam# gaat koortsachtig aan de slag maar maakt niks af',
              },
              {
                mask: 'd',
                label:
                  '#naam# neemt de touwtjes stevig in handen en vertelt iedereen wat ie moet doen ',
              },
              {
                mask: 'e',
                label: '#naam# gaat altijd maar door, ten koste van zichzelf',
              },
              {
                mask: 'f',
                label:
                  '#naam# zit er bovenop en push zichzelf - en anderen - tot het uiterste',
              },
            ],
          },
          {
            question: 'Welke plek heeft #naam# in een groep?',
            header: '',
            options: [
              {
                mask: 'a',
                label: '#naam# hoort er net niet helemaal bij',
              },
              // {
              //   mask: 'b',
              //   label: 'Vriendelijk en vrijgevig, maar toch ook veeleisend',
              // },
              {
                mask: 'c',
                label: '#naam# zorgt voor de verbinding',
              },
              {
                mask: 'd',
                label: '#naam# heeft vaak de leiding',
              },
              {
                mask: 'e',
                label: '#naam# maakt zich dienstbaar',
              },
              {
                mask: 'f',
                label: '#naam# is de regelneef',
              },
            ],
          },
          {
            question: 'Waar is #naam# beter in dan anderen?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Out-of-the-box denken',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Dat ik met complimenten strooi en nooit iemands verjaardag vergeet',
              // },
              {
                mask: 'c',
                label: 'Een fijne veilige sfeer creëren',
              },
              {
                mask: 'd',
                label: 'Rücksichlos doorpakken',
              },
              {
                mask: 'e',
                label: 'Sterke schouders bieden om op te leunen',
              },
              {
                mask: 'f',
                label: 'Iets tot in de puntjes afmaken',
              },
            ],
          },
          {
            question: 'Waarin kan #naam# soms doorslaan?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Dingen te ingewikkeld maken',
              },
              // {
              //   mask: 'b',
              //   label:
              //     "Ik kan soms een beetje 'needy' overkomen, het is eigenlijk nooit genoeg",
              // },
              {
                mask: 'c',
                label: 'Zichzelf teveel wegcijferen',
              },
              {
                mask: 'd',
                label: 'Zijn/haar zin teveel doordrijven',
              },
              {
                mask: 'e',
                label: 'Teveel hooi op zijn/haar vork nemen',
              },
              {
                mask: 'f',
                label: 'Controlfreak zijn',
              },
            ],
          },
          {
            question: 'Ik bewonder ...',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'dat #naam# creatief is',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik voel me vaak onmachtig en gefrustreerd, het leven voelt vaak oneerlijk',
              // },
              {
                mask: 'c',
                label: 'dat #naam# een bruggenbouwer is',
              },
              {
                mask: 'd',
                label: 'dat #naam# daadkrachtig is',
              },
              {
                mask: 'e',
                label: 'dat #naam# een doorzetter is',
              },
              {
                mask: 'f',
                label: 'dat #naam# veel kan bereiken',
              },
            ],
          },
          {
            question: 'Hoe reageert #naam# op een conflict?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Door er een intellectueel debat van te maken',
              },
              // { mask: 'b', label: 'Dat er niet genoeg is voor mij ' },
              {
                mask: 'c',
                label: 'Door het heel emotioneel te maken',
              },
              {
                mask: 'd',
                label: 'Door de strijd aan te gaan',
              },
              {
                mask: 'e',
                label: 'Door frustratie weg te slikken',
              },
              {
                mask: 'f',
                label: 'Door koel en afstandelijk te worden',
              },
            ],
          },
          {
            question: 'Soms vind ik dat #naam#...',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'te afstandelijk is',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label: 'teveel een pleaser is',
              },
              {
                mask: 'd',
                label: 'te bot is',
              },
              {
                mask: 'e',
                label: 'te serieus is',
              },
              {
                mask: 'f',
                label: 'te kritisch is',
              },
            ],
          },
          {
            question: 'Heb je nog tips of toevoegingen die mij helpen mezelf verder te ontwikkelen?',
            type: 'open',
            header: '',
            placeholder: 'Mijn tip is...',
            last: true
          },
        ],
      },
      {
        name: 'egotypescan-en',
        language: 'en',
        introductionStart: require('@/assets/html/intro-egotypescan-en-start.html'),
        introductionEnd: require('@/assets/html/intro-egotypescan-en-end.html'),
        resultText: require('@/assets/html/results-self-en.html'),
        mailtemplate: 'finished_self_en',
        isForOther: false,
        // showEndscreenResult: false,
        mailToSubject: true,
        mailToOwner: false,
        allPointsMandatory: true,
        questions: [
          {
            question: "What's your standout quality?",
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Seeing the bigger picture',
              },
              // {
              //   mask: 'b',
              //   label: 'Als ik iemand blij heb gemaakt met mijn hulp',
              // },
              {
                mask: 'c',
                label: 'Sensing what others need',
              },
              {
                mask: 'd',
                label: 'Challenging the status quo',
              },
              {
                mask: 'e',
                label: 'Being helpful',
              },
              {
                mask: 'f',
                label: 'Elevating the level to a higher standard',
              },
            ],
          },
          {
            question: 'How do you connect with others?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'I engage in conversation about the content',
              },
              // { mask: 'b', label: 'Dat ik altijd voor iedereen klaar sta' },
              { mask: 'c', label: 'I make friends with everyone' },
              {
                mask: 'd',
                label: 'I make everyone laugh',
              },
              { mask: 'e', label: 'I initiate deep conversations' },
              {
                mask: 'f',
                label: 'I charm everyone effortlessly',
              },
            ],
          },
          {
            question: 'How do others see you?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'They admire my autonomy but also find me elusive',
              },
              // { mask: 'b', label: 'Als geven en nemen in balans is' },
              {
                mask: 'c',
                label:
                  "They feel understood, but they're not sure exactly what's on my mind",
              },
              {
                mask: 'd',
                label: 'They see me as strong, but also a bit tough',
              },
              {
                mask: 'e',
                label: "They can rely on me, but there isn't much room for fun",
              },
              {
                mask: 'f',
                label: 'They find me impressive, but also demanding',
              },
            ],
          },
          {
            question: 'What do you find challenging?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Engaging in small talk at a busy gathering',
              },
              // {
              //   mask: 'b',
              //   label: 'Ik hoop dat ik kan bijdragen aan meer rechtvaardigheid',
              // },
              {
                mask: 'c',
                label: "Not 'taking things so personally'",
              },
              {
                mask: 'd',
                label: 'Being dependent on others',
              },
              { mask: 'e', label: 'Letting things slide' },
              {
                mask: 'f',
                label: 'Letting go of my high standards',
              },
            ],
          },
          {
            question: 'How do you deal with pressure?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'I turn inward and stop communicating',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik ben warm, attent en rechtvaardig, ik weet precies wat iedereen nodig heeft',
              // },
              {
                mask: 'c',
                label: 'I eagerly start working on things but don\'t quite finish them',
              },
              {
                mask: 'd',
                label:
                  'I firmly take control and tell everyone what to do ',
              },
              {
                mask: 'e',
                label: 'I always keep going, at the expense of myself',
              },
              {
                mask: 'f',
                label:
                  'I\'m on top of things, pushing myself - and others - to the limit',
              },
            ],
          },
          {
            question: 'How do you behave in a group?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'I feel like I don\'t quite belong',
              },
              // {
              //   mask: 'b',
              //   label: 'Vriendelijk en vrijgevig, maar toch ook veeleisend',
              // },
              {
                mask: 'c',
                label: 'I facilitate connections',
              },
              {
                mask: 'd',
                label: 'I often take the lead',
              },
              {
                mask: 'e',
                label: 'I make myself serviceable',
              },
              {
                mask: 'f',
                label: 'I ensure everything goes according to plan',
              },
            ],
          },
          {
            question: 'In what areas do you excel compared to others?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Thinking outside the box',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Dat ik met complimenten strooi en nooit iemands verjaardag vergeet',
              // },
              {
                mask: 'c',
                label: 'Empathising with others',
              },
              {
                mask: 'd',
                label: 'Taking initiative and following through',
              },
              {
                mask: 'e',
                label: 'Providing strong shoulders to lean on',
              },
              {
                mask: 'f',
                label: 'Finishing something, down to the last detail',
              },
            ],
          },
          {
            question: 'Where might you sometimes go overboard?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'I tend to make things too complicated ',
              },
              // {
              //   mask: 'b',
              //   label:
              //     "Ik kan soms een beetje 'needy' overkomen, het is eigenlijk nooit genoeg",
              // },
              {
                mask: 'c',
                label: 'I tend to set myself to the side too much ',
              },
              {
                mask: 'd',
                label: 'I push my agenda too hard',
              },
              {
                mask: 'e',
                label: "I take on too much ",
              },
              {
                mask: 'f',
                label: 'I can really be a control freak',
              },
            ],
          },
          {
            question: 'What do others admire about you?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'The fact that I\'m creative ',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik voel me vaak onmachtig en gefrustreerd, het leven voelt vaak oneerlijk',
              // },
              {
                mask: 'c',
                label: 'That I am a bridge-builder',
              },
              {
                mask: 'd',
                label: 'That I am decisive',
              },
              {
                mask: 'e',
                label: 'That I am persistent',
              },
              {
                mask: 'f',
                label: 'That I can achieve a lot',
              },
            ],
          },
          {
            question: 'What does your inner critic say?',
            header: '',
            options: [
              {
                mask: 'a',
                label: '"No one really understands me"',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik weet dat ik een kameleon ben, maar ik wil graag de harmonie bewaren',
              // },
              {
                mask: 'c',
                label: '"My opinion doesn\'t matter"',
              },
              {
                mask: 'd',
                label: '"I must not show weakness"',
              },
              {
                mask: 'e',
                label: '"Keep calm and carry on"',
              },
              {
                mask: 'f',
                label: '"It\'s never good enough"',
              },
            ],
          },
          {
            question: 'How do you handle conflict?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'I turn it into an intellectual debate',
              },
              // { mask: 'b', label: 'Dat er niet genoeg is voor mij ' },
              {
                mask: 'c',
                label: 'I make it very emotional ',
              },
              {
                mask: 'd',
                label: 'I meet conflict head-on',
              },
              {
                mask: 'e',
                label: 'I swallow my frustrations ',
              },
              {
                mask: 'f',
                label: 'I become cool and distant',
              },
            ],
          },
          {
            question: 'Sometimes, people say that I\'m...',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Being too distant',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label: 'Being too much of a people-pleaser',
              },
              {
                mask: 'd',
                label: 'Being too blunt',
              },
              {
                mask: 'e',
                label: 'Being too serious',
              },
              {
                mask: 'f',
                label: 'Being too critical',
              },
            ],
          },
          {
            question: 'How do you justify your own behaviour?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  '"I know I use a lot of words, but otherwise, they won\'t get it"',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label:
                  '"I know I\'m a chameleon, but I want to make others happy"',
              },
              {
                mask: 'd',
                label:
                  '"I know I\'m direct, but the end justifies the means"',
              },
              {
                mask: 'e',
                label:
                  '"I know I struggle with setting boundaries, but someone\'s gotta do it, right?"',
              },
              {
                mask: 'f',
                label:
                  '"I know I\'m micromanaging, but I just want things to go well"',
              },
            ],
          },
          {
            question: 'How do you deal with criticism?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  "I get lost in my thoughts; the criticism only sinks in after a few days",
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label: 'I panic and feel hurt and rejected',
              },
              {
                mask: 'd',
                label:
                  'I don\'t really let it sink in and just hit back immediately',
              },
              {
                mask: 'e',
                label:
                  'It feels so unfair... don\'t you see how hard I\'ve tried?',
              },
              {
                mask: 'f',
                label:
                  'Criticism hits me hard; it feels like failure',
              },
            ],
          },
          {
            question: 'How do you handle trust?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'I don\'t really trust the world',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label:
                  'I trust others a lot, but myself... not so much',
              },
              {
                mask: 'd',
                label: 'I trust everyone - and actually, no one',
              },
              {
                mask: 'e',
                label:
                  'I want everyone to trust me (but preferably not the other way around)',
              },
              {
                mask: 'f',
                label:
                  'I seem confident but don\'t trust anyone with my imperfections',
              },
            ],
          },
          {
            question: 'What are you most afraid of?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Losing my independence',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label: 'Being let down',
              },
              {
                mask: 'd',
                label: 'Being vulnerable',
              },
              {
                mask: 'e',
                label: 'Not being of significance',
              },
              {
                mask: 'f',
                label: 'Being rejected',
              },
            ],
          },
          {
            question: 'How does anger manifest in you?',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'I "implode": I feel a lot of tension in my body and then just shut down',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label:
                  'When I\'m angry, I tend to magnify things and get emotional',
              },
              {
                mask: 'd',
                label: "I \"explode\": when I'm angry, everyone knows it",
              },
              {
                mask: 'e',
                label:
                  'I bottle up my anger -- until it all bursts out',
              },
              {
                mask: 'f',
                label:
                  'I become a bit cool and strict, and feeling somewhat superior to others',
              },
            ],
          },
          {
            question: 'What does fear do to you? ',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'I get caught in a bit of a mindfuck and see dangers that aren\'t there',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label:
                  'I call everyone to get confirmation that it will be okay',
              },
              {
                mask: 'd',
                label: 'I deny that I have fear, even to myself',
              },
              {
                mask: 'e',
                label: 'I suffer in silence and can\'t always see a way out',
              },
              {
                mask: 'f',
                label: 'I freeze up because I no longer feel in control',
              },
            ],
          },
        ],
      },
      {
        name: 'egotypescan-other-en',
        language: 'en',
        mailtemplate: 'finished_other_en',
        isForOther: true,
        // showEndscreenResult: false,
        mailToSubject: true,
        mailToOwner: false,
        allPointsMandatory: false,
        // introductionStart: require('@/assets/html/intro-egotypescan-other-en-start.html'),
        // introductionEnd: require('@/assets/html/intro-egotypescan-other-en-end.html'),
        preStart: require('@/assets/html/prestart-egotypescan-other-en.html'),
        resultText: require('@/assets/html/results-other-en.html'),
        questions: [
          {
            question: 'What\'s #naam#\'s standout quality?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Seeing the bigger picture',
              },
              // {
              //   mask: 'b',
              //   label: 'Als ik iemand blij heb gemaakt met mijn hulp',
              // },
              {
                mask: 'c',
                label: 'Sensing what others need',
              },
              {
                mask: 'd',
                label: 'Challenging the status quo',
              },
              {
                mask: 'e',
                label: 'Being helpful',
              },
              {
                mask: 'f',
                label: 'Elevating the level to a higher standard',
              },
            ],
          },
          {
            question: 'How does #naam# connect with others?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Engaging in a conversation about the content',
              },
              // { mask: 'b', label: 'Dat ik altijd voor iedereen klaar sta' },
              { mask: 'c', label: 'By making friends with everyone' },
              {
                mask: 'd',
                label: 'By making everyone laugh',
              },
              {
                mask: 'e',
                label: 'By initiating deep conversations',
              },
              {
                mask: 'f',
                label: 'By effortlessly charming everyone',
              },
            ],
          },
          {
            question: 'How I see #naam#...',
            header: '',
            options: [
              {
                mask: 'a',
                label:
                  'I admire #naam#\'s autonomy but also find them elusive',
              },
              // { mask: 'b', label: 'Als geven en nemen in balans is' },
              {
                mask: 'c',
                label:
                  'I feel understood, but I\'m not exactly sure what\'s on #naam#\'s mind',
              },
              {
                mask: 'd',
                label: 'I find #naam# strong, but also a bit tough',
              },
              {
                mask: 'e',
                label:
                  'I can rely on #naam#, but there isn\'t much room for fun',
              },
              {
                mask: 'f',
                label: 'I find #naam# rather impressive, but also demanding',
              },
            ],
          },
          {
            question: 'What does #naam# find challenging?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Engaging in small talk at a busy gathering',
              },
              // {
              //   mask: 'b',
              //   label: 'Ik hoop dat ik kan bijdragen aan meer rechtvaardigheid',
              // },
              {
                mask: 'c',
                label: 'Not "taking things so personally"',
              },
              {
                mask: 'd',
                label: 'Being dependent on others',
              },
              { mask: 'e', label: 'Letting things slide' },
              {
                mask: 'f',
                label: 'Letting go of high standards',
              },
            ],
          },
          {
            question: 'How does #naam# deal with pressure?',
            header: '',
            options: [
              {
                mask: 'a',
                label: '#naam# turns inward and stops communicating',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik ben warm, attent en rechtvaardig, ik weet precies wat iedereen nodig heeft',
              // },
              {
                mask: 'c',
                label:
                  '#naam# eagerly starts working on things but doesn\'t quite finish them',
              },
              {
                mask: 'd',
                label:
                  '#naam# firmly takes control and tells everyone what to do',
              },
              {
                mask: 'e',
                label: '#naam# always keeps going, at the expense of themselves',
              },
              {
                mask: 'f',
                label:
                  '#naam# is on top of things, pushing themselves - and others - to the limit',
              },
            ],
          },
          {
            question: 'How does #naam# behave in a group?',
            header: '',
            options: [
              {
                mask: 'a',
                label: '#naam# feels like they don\'t quite belong',
              },
              // {
              //   mask: 'b',
              //   label: 'Vriendelijk en vrijgevig, maar toch ook veeleisend',
              // },
              {
                mask: 'c',
                label: '#naam# facilitates connections',
              },
              {
                mask: 'd',
                label: '#naam# often takes the lead',
              },
              {
                mask: 'e',
                label: '#naam# makes themselves serviceable',
              },
              {
                mask: 'f',
                label: '#naam# is the ultimate organiser',
              },
            ],
          },
          {
            question: 'In what areas does #naam# excel compared to others?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Thinking outside the box',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Dat ik met complimenten strooi en nooit iemands verjaardag vergeet',
              // },
              {
                mask: 'c',
                label: 'Creating a pleasant and safe atmosphere',
              },
              {
                mask: 'd',
                label: 'Taking resolute action',
              },
              {
                mask: 'e',
                label: 'Providing strong shoulders to lean on',
              },
              {
                mask: 'f',
                label: 'Finishing something, down to the last detail',
              },
            ],
          },
          {
            question: 'Where might #naam# sometimes go overboard?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Making things too complicated',
              },
              // {
              //   mask: 'b',
              //   label:
              //     "Ik kan soms een beetje 'needy' overkomen, het is eigenlijk nooit genoeg",
              // },
              {
                mask: 'c',
                label: '#naam# tends to set themselves to the side too much',
              },
              {
                mask: 'd',
                label: 'Pushing their agenda too hard',
              },
              {
                mask: 'e',
                label: 'Taking on too much',
              },
              {
                mask: 'f',
                label: 'Being a bit of a control freak',
              },
            ],
          },
          {
            question: 'I admire…',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'that #naam# is creative',
              },
              // {
              //   mask: 'b',
              //   label:
              //     'Ik voel me vaak onmachtig en gefrustreerd, het leven voelt vaak oneerlijk',
              // },
              {
                mask: 'c',
                label: 'that #naam# is a bridge-builder',
              },
              {
                mask: 'd',
                label: 'that #naam# is decisive',
              },
              {
                mask: 'e',
                label: 'that #naam# is persistent',
              },
              {
                mask: 'f',
                label: 'that #naam# can achieve a lot',
              },
            ],
          },
          {
            question: 'How does #naam# handle conflict?',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Turning it into an intellectual debate',
              },
              // { mask: 'b', label: 'Dat er niet genoeg is voor mij ' },
              {
                mask: 'c',
                label: 'By making it very emotional',
              },
              {
                mask: 'd',
                label: 'Meeting conflict head-on',
              },
              {
                mask: 'e',
                label: 'Swallowing frustrations',
              },
              {
                mask: 'f',
                label: 'By becoming cool and distant',
              },
            ],
          },
          {
            question: 'Sometimes, I think that #naam# is…',
            header: '',
            options: [
              {
                mask: 'a',
                label: 'Being too distant',
              },
              // { mask: 'b', label: 'Andere mensen gelukkig maken ' },
              {
                mask: 'c',
                label: 'Being too much of a people-pleaser',
              },
              {
                mask: 'd',
                label: 'Being too blunt',
              },
              {
                mask: 'e',
                label: 'Being too serious',
              },
              {
                mask: 'f',
                label: 'Being too critical',
              },
            ],
          },
          {
            question: 'Any tips or comments that could help me grow?',
            type: 'open',
            header: '',
            placeholder: 'My tip is...',
            last: true
          },
        ],
      },
    ],
    masks: {
      persoonlijk: {
        a: {
          name: 'De observer',
          info: require('@/assets/html/mask-a-persoonlijk.html'),
        },
        // b: {
        //   name: 'De gever',
        //   info: require('@/assets/html/mask-b-persoonlijk.html'),
        // },
        c: {
          name: 'De connector',
          info: require('@/assets/html/mask-c-persoonlijk.html'),
        },
        d: {
          name: 'De powerhouse',
          info: require('@/assets/html/mask-d-persoonlijk.html'),
        },
        e: {
          name: 'De carrier',
          info: require('@/assets/html/mask-e-persoonlijk.html'),
        },
        f: {
          name: 'De achiever',
          info: require('@/assets/html/mask-f-persoonlijk.html'),
        },
      },
      organisatie: {
        a: {
          name: 'De Idealistische Organisatie',
          info: require('@/assets/html/mask-a-organisatie.html'),
        },
        b: {
          name: 'De Gevende Organisatie',
          info: require('@/assets/html/mask-b-organisatie.html'),
        },
        c: {
          name: 'De Verbindende Organisatie',
          info: require('@/assets/html/mask-c-organisatie.html'),
        },
        d: {
          name: 'De Strijdende Organisatie',
          info: require('@/assets/html/mask-d-organisatie.html'),
        },
        e: {
          name: 'De Dragende Organisatie',
          info: require('@/assets/html/mask-e-organisatie.html'),
        },
        f: {
          name: 'De Presterende Organisatie',
          info: require('@/assets/html/mask-f-organisatie.html'),
        },
      },
      personal: {
        a: {
          name: 'The Observer',
          info: require('@/assets/html/mask-a-personal.html'),
        },
        // b: {
        //   name: 'The Giver',
        //   info: require('@/assets/html/mask-b-personal.html'),
        // },
        c: {
          name: 'The Connector',
          info: require('@/assets/html/mask-c-personal.html'),
        },
        d: {
          name: 'The Powerhouse',
          info: require('@/assets/html/mask-d-personal.html'),
        },
        e: {
          name: 'The Carrier',
          info: require('@/assets/html/mask-e-personal.html'),
        },
        f: {
          name: 'The Achiever',
          info: require('@/assets/html/mask-f-personal.html'),
        },
      },
      organisation: {
        a: {
          name: 'The Idealistic Organisation ',
          info: require('@/assets/html/mask-a-organisation.html'),
        },
        b: {
          name: 'The Giving Organisation',
          info: require('@/assets/html/mask-b-organisation.html'),
        },
        c: {
          name: 'The Connecting Organisation',
          info: require('@/assets/html/mask-c-organisation.html'),
        },
        d: {
          name: 'The Competing Organisation ',
          info: require('@/assets/html/mask-d-organisation.html'),
        },
        e: {
          name: 'The Carrying Organisation ',
          info: require('@/assets/html/mask-e-organisation.html'),
        },
        f: {
          name: 'The Performing Organisation',
          info: require('@/assets/html/mask-f-organisation.html'),
        },
      },
      egotypescan: {
        a: {
          name: 'De Observer',
          info: require('@/assets/html/mask-a-egotypescan.html'),
        },
        // b: {
        //   name: 'De Gever',
        //   info: require('@/assets/html/mask-b-egotypescan.html'),
        // },
        c: {
          name: 'De Connector',
          info: require('@/assets/html/mask-c-egotypescan.html'),
        },
        d: {
          name: 'De Powerhouse',
          info: require('@/assets/html/mask-d-egotypescan.html'),
        },
        e: {
          name: 'De Carrier',
          info: require('@/assets/html/mask-e-egotypescan.html'),
        },
        f: {
          name: 'De Achiever',
          info: require('@/assets/html/mask-f-egotypescan.html'),
        },
      },
      'egotypescan-other': {
        a: {
          name: 'De Observer',
          info: require('@/assets/html/mask-a-egotypescan-other.html'),
        },
        // b: {
        //   name: 'De Gever',
        //   info: require('@/assets/html/mask-b-egotypescan-other.html'),
        // },
        c: {
          name: 'De Connector',
          info: require('@/assets/html/mask-c-egotypescan-other.html'),
        },
        d: {
          name: 'De Powerhouse',
          info: require('@/assets/html/mask-d-egotypescan-other.html'),
        },
        e: {
          name: 'De Carrier',
          info: require('@/assets/html/mask-e-egotypescan-other.html'),
        },
        f: {
          name: 'De Achiever',
          info: require('@/assets/html/mask-f-egotypescan-other.html'),
        },
      } ,
      'egotypescan-en': {
        a: {
          name: 'The Observer',
          // info: require('@/assets/html/mask-a-egotypescan.html'),
        },
        // b: {
        //   name: 'De Gever',
        //   info: require('@/assets/html/mask-b-egotypescan.html'),
        // },
        c: {
          name: 'The Connector',
          // info: require('@/assets/html/mask-c-egotypescan.html'),
        },
        d: {
          name: 'The Powerhouse',
          // info: require('@/assets/html/mask-d-egotypescan.html'),
        },
        e: {
          name: 'The Carrier',
          // info: require('@/assets/html/mask-e-egotypescan.html'),
        },
        f: {
          name: 'The Achiever',
          // info: require('@/assets/html/mask-f-egotypescan.html'),
        },
      },
      'egotypescan-other-en': {
        a: {
          name: 'The Observer',
          // info: require('@/assets/html/mask-a-egotypescan-other.html'),
        },
        // b: {
        //   name: 'The Gever',
        //   info: require('@/assets/html/mask-b-egotypescan-other.html'),
        // },
        c: {
          name: 'The Connector',
          // info: require('@/assets/html/mask-c-egotypescan-other.html'),
        },
        d: {
          name: 'The Powerhouse',
          // info: require('@/assets/html/mask-d-egotypescan-other.html'),
        },
        e: {
          name: 'The Carrier',
          // info: require('@/assets/html/mask-e-egotypescan-other.html'),
        },
        f: {
          name: 'The Achiever',
          // info: require('@/assets/html/mask-f-egotypescan-other.html'),
        },
      },
    },
  },
  mutations: {
    SET_TYPE(state, context) {
      state.status.type = context
    },
    SET_FROM_NAME(state, context) {
      state.status.fromName = context
    },
    SET_IS_FOR(state, context) {
      state.status.isFor = context
    },
    SET_IS_FOR_EMAIL(state, context) {
      state.status.isForEmail = context
    },
    SET_EMAIL_TEMPLATE(state, context) {
      state.status.mailTemplate = context
    },
    SET_LANGUAGE(state, context) {
      // console.log('setting language to ', context)
      state.status.language = context
    },
    SET_ORGANISATION_ID(state, context) {
      // console.log('setting language to ', context)
      state.status.organisationId = context
    },
  },
  actions: {
    setLanguage({ commit, getters, state }) {
      if (state.status.type) {
        const language = getters['questionnaire'](state.status.type).language
        commit('SET_LANGUAGE', language)

        // Piggypack of the language set to also set email template
        const mailTemplate =
          getters['questionnaire'](state.status.type).mailtemplate || null
        commit('SET_EMAIL_TEMPLATE', mailTemplate)
      }
    },
  },
  getters: {
    questionnaire(state) {
      return (type) =>
        state.questionnaires.find((item) => {
          return item.name === type
        })
    },
    question(state, getters) {
      return (index) =>
        getters.questionnaire(state.status.type).questions[index]
    },
  },
  modules: {
    answers: answersModule,
    string: stringModule,
  },
})
