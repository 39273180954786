// import firebase from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions'
// import 'firebase/auth';
// import 'firebase/app-check';
import { db, firebaseApp} from '@/services/firebaseInit.js'
import { collection, doc, getDoc } from 'firebase/firestore'
import {useStore} from 'vuex'

// const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate('6LeOYWMbAAAAAGxcp8LoB5AJyk68HbkwuePuW60G');


const functions = getFunctions(firebaseApp, 'europe-west2')

export const createResult = async (data) => {
  const store = useStore()
  const questionnaire = store.getters.questionnaire( data.type )

  // Honor mail flags before creating.
  if (!questionnaire.mailToSubject) {
    delete data.to // Subject does not receive mail

    if ( questionnaire.mailToOwner ) {
      data.to = process.env.VUE_APP_OWNER_EMAIL
    }
  } else if ( questionnaire.mailToOwner ) {
    // Subject does get mail, so owner needs to be BCC
    data.bcc = process.env.VUE_APP_OWNER_EMAIL
  }

  const createMailResult = httpsCallable(functions, 'createMailResult')
  try {
    const results = await createMailResult(data)
    return results
  } catch (error) {
    return error
  }
}


export const getResult = async (id) => {
  const docRef = doc(db, "results", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {...docSnap.data(), id: id, path: docSnap.ref.path}
  }
}