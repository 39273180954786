<template>
  <div id="nav" v-if="$route.query.nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/resultaat">Resultaat</router-link> |
    <router-link to="/v/persoonlijk">Persoonlijk</router-link> |
    <router-link to="/v/organisatie">Organisatie</router-link>
  </div>

  <router-view v-slot="{ Component }">
    <transition name="slide-left">
      <component :is="Component" :key="$route.fullPath" />
    </transition>
  </router-view>
</template>

<style lang="scss">
// @font-face {
//   font-family: 'Helvetica Neue LT W05_55 Roman';
//   src: url('./assets/fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2') format('woff2'),
//     url('./assets/fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff') format('woff');
// }

// @font-face {
//   font-family: 'Helvetica Neue LT W05_75 Bold';
//   src: url('./assets/fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2') format('woff2'),
//     url('./assets/fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff') format('woff');
// }

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #fff;
}

#app {
  // --accent: #f8ccad;

  --primary: #b8a579;
  --secondary: #83A876;
  --accent: #F0F0EA;
  --alert: #ce0814;
  --font-regular: 'Inter', sans-serif;
  --font-bold: "degular-display", sans-serif;

  position: relative;
  font-family: var(--font-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  // background-color: #000;
  // max-width: 980px;
  // margin: 0 auto;
  // overflow: hidden;
  width: 100%;
}

.container {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;

  @media all and (max-width: 500px) {
    padding-inline: 1rem;

  }
}

@media all and (min-width: 900px) {
  .white-sides {
    background-color: #fff;
  }
}

#nav {
  a {
    font-weight: bold;
    color: #666;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-bold);
  font-weight: normal;
}

h2 {
  margin: 0;
  font-size: 2rem;
}

strong {
  font-family: var(--font-bold);
  // font-weight: 600;
}

input[type="text"],
input[type="email"],
input[type="number"] {
  background-color: #f5f5f5;
  border: none;
  padding: 0.7rem 0.6rem;
}

.btn {
  appearance: none;
  border: 0;
  background: #161210;
  // font-weight: bold;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
  cursor: pointer;
  height: 2.9375rem;
  border-radius: 1.6875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 1.1875rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
  text-decoration: none;


  span {
    margin-left: 1rem;
  }

  img {
    max-height: 1.2em;
    width: auto;
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &--diap {
    color: #fff;
    font-size: 1.1rem;
  }

  &--text {
    text-transform: none;
    height: 1em;
    padding: 0;
    background-color: transparent;
    color: #000;
  }

  &--outline {
    background: transparent;
    border: 1px solid #000;
    color: #000;


  }

  &:disabled {
    background: rgb(233, 233, 233);
    border-color: rgb(148, 148, 148);
    color: rgb(148, 148, 148);
    cursor: not-allowed;
  }

  @media (hover: hover) and (pointer: fine) {
    &:not(.btn--text):not(:disabled):hover {
      background-color: var(--accent);
      color: #000;
    }

    &--diap:hover {
      color: var(--accent);
    }
  }
}

.btn--large {
  display: flex;
  background-color: var(--accent);
  color: #000;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 4rem;
  margin: 0 auto 2rem;
  max-width: 400px;
  // width: 100%;

  &:disabled {
    background-color: #888;

    &:hover {
      background-color: #888;
      color: #000;
      cursor: not-allowed;
    }
  }

  &--alert {
    background-color: var(--alert);
    color: #fff;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--alert);
      color: #fff;
    }

    &--alert:hover {
      background-color: var(--accent);
      color: #000;
    }
  }

  @media all and (min-width: 500px) {
    font-size: 2rem;
  }

  span {
    flex: 1;
    text-align: center;
  }

  // Icon
  i {
    font-style: normal;
    font-size: 3rem;
  }
}

.has-background {
  padding: 2rem 2rem 2rem;
  // margin-bottom: 2rem;
}

.background-salmon {
  background-color: var(--accent);
  color: #000;
}

.background-black {
  background-color: #000;
  color: #fff;
}

.background-white {
  background-color: #fff;
  color: #000;
}

.logo-container {
  display: flex;
  justify-content: center;
  padding: 1rem 1rem 1rem;
  height: 102px;

  .logo {
    max-width: 300px;
    flex: 1;
    margin-top: 2rem;

    &--small {
      max-width: 170px;
      margin-top: 0rem;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.slide-left-leave-active {
  position: absolute;
  width: 100%;
  transform: translateX(-100%);
}

.slide-left-enter-from {
  transform: translateX(100%);
}
</style>
